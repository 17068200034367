import React from "react"
import {
    Navigate,
    Outlet,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from "react-router"
import ProtectedRoute from "./components/Other/ProtectedRoute"
import ErrorElement from "./components/Other/ErrorElement"
import * as Sentry from "@sentry/react"
import { baseAPIUrl } from "server/baseURL"
import useVersionCheck from "hooks/useVersionCheck"

Sentry.init({
    dsn: "https://bb1e7188a787bfd1fcdf522435980b2d@o4506353853923328.ingest.us.sentry.io/4506904268308480",
    tunnel: `${baseAPIUrl}/logs/sentry`,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV7BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration(),
    ],
    enabled: process.env.NODE_ENV === "production",
    autoSessionTracking: process.env.NODE_ENV === "production",
    normalizeDepth: 15,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 1.0,
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["api.appnflat.com", "api-staging.appnflat.com"],
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 0,
    replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0,
})

function Root() {
    useVersionCheck()
    return (
        <Sentry.ErrorBoundary fallback={(e) => <ErrorElement error={e.error} />} showDialog>
            <ProtectedRoute>
                <Outlet />
            </ProtectedRoute>
        </Sentry.ErrorBoundary>
    )
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouterV7(createBrowserRouter)

const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root />} ErrorBoundary={ErrorElement}>
            <Route index element={<Navigate to="/dashboard" replace />} />
            <Route
                path="/version"
                element={process.env.REACT_APP_VERSION + " " + process.env.REACT_APP_BUILD_NUMBER}
            />
            <Route path=".well-known/change-password" element={<Navigate to="/account" />} />
            <Route path="signin" lazy={() => import("./components/Account/SignIn")} />
            <Route path="signup" lazy={() => import("./components/Account/SignUp")} />
            <Route path="_auth" lazy={() => import("./components/Account/AuthActionHandler")} />
            <Route path="dashboard" lazy={() => import("./components/Shared/SharedDashboard")} />
            <Route path="account" lazy={() => import("./components/Account/Account")} />
            <Route path="building/:buildingRef/:fiscalYear?">
                <Route path="account" lazy={() => import("./components/Account/Account")} />
                <Route
                    index
                    lazy={() => import("./components/BuildingDashboard/BuildingDashboard")}
                />
                <Route
                    path="dashboard"
                    lazy={() => import("./components/BuildingDashboard/BuildingDashboard")}
                />
                <Route path="units">
                    <Route
                        path="transactions/:unitUUID?"
                        lazy={() => import("./components/Units/UnitTransactions")}
                    />
                    <Route
                        path="info/:unitUUID?"
                        lazy={() => import("./components/Units/UnitInfo")}
                    />
                    <Route
                        path="penalties"
                        lazy={() => import("./components/Financial/Penalties")}
                    />
                    <Route path="reports" lazy={() => import("./components/Units/UnitReports")} />
                </Route>
                <Route path="people">
                    <Route
                        path="info/:personUUID?"
                        lazy={() => import("./components/People/PersonInfo")}
                    />
                    <Route
                        path="reports"
                        lazy={() => import("./components/People/PersonReports")}
                    />
                </Route>
                <Route path="suppliers">
                    <Route
                        path="transactions/:supplierUUID?"
                        lazy={() => import("./components/Suppliers/SupplierTransactions")}
                    />
                    <Route
                        path="info/:supplierUUID?"
                        lazy={() => import("./components/Suppliers/SupplierInfo")}
                    />
                    <Route
                        path="invoices/:supplierUUID?"
                        lazy={() => import("./components/Suppliers/SupplierInvoices")}
                    />
                    <Route
                        path="reports"
                        lazy={() => import("./components/Suppliers/SupplierReports")}
                    />
                </Route>
                <Route path="payments">
                    <Route path="deposits" lazy={() => import("./components/Payments/Deposits")} />
                    <Route
                        path="wire-transfers"
                        lazy={() => import("./components/Payments/WireTransfers/WireTransfers")}
                    />
                    <Route
                        path="checks"
                        lazy={() => import("./components/Payments/Checks/Checks")}
                    />
                    <Route
                        path="reconciliation"
                        lazy={() => import("./components/Financial/BankReconciliation")}
                    />
                    <Route path="approval" lazy={() => import("./components/Payments/Approval")} />
                    <Route path="otonom" lazy={() => import("./components/Payments/Otonom")} />
                    <Route
                        path="reports"
                        lazy={() => import("./components/Payments/PaymentsReports")}
                    />
                </Route>
                <Route path="banks">
                    <Route
                        path="info/:bankUUID?"
                        lazy={() => import("./components/Banks/BankInfo")}
                    />
                    <Route
                        path="transactions/:bankUUID?"
                        lazy={() => import("./components/Banks/BankTransactions")}
                    />
                    <Route
                        path="reconciliation"
                        lazy={() => import("./components/Financial/BankReconciliation")}
                    />
                    <Route path="reports" lazy={() => import("./components/Banks/BankReports")} />
                </Route>
                <Route path="categories">
                    <Route
                        path="transactions/:categoryUUID?"
                        lazy={() => import("./components/Categories/CategoryTransactions")}
                    />
                    <Route
                        path="info/:categoryUUID?"
                        lazy={() => import("./components/Categories/CategoryInfo")}
                    />
                    <Route
                        path="budget"
                        lazy={() => import("./components/Categories/CategoryBudget")}
                    />
                    <Route
                        path="accountant-writings"
                        lazy={() => import("./components/Categories/CategoryAccountantWritings")}
                    />
                    <Route
                        path="financial-statement"
                        lazy={() =>
                            import("./components/Categories/FinancialStatement/FinancialStatement")
                        }
                    />
                    <Route
                        path="fiscal-year"
                        lazy={() => import("./components/Categories/CategoryFiscalYear")}
                    />
                    <Route
                        path="reports"
                        lazy={() => import("./components/Categories/CategoryReports")}
                    />
                </Route>
                <Route path="posts">
                    <Route path="reminders" lazy={() => import("./components/Posts/Reminders")} />
                    <Route
                        path="admin"
                        lazy={() => import("./components/Posts/Wrappers/AdminPosts")}
                    />
                    <Route path="all" lazy={() => import("./components/Posts/Wrappers/AllPosts")} />
                    <Route path="my" lazy={() => import("./components/Posts/Wrappers/MyPosts")} />
                    <Route path="emails" lazy={() => import("./components/Posts/EmailTemplates")} />
                </Route>
                <Route path="requests">
                    <Route path="all" lazy={() => import("./components/Requests/Requests")} />
                </Route>
                <Route path="admin">
                    <Route
                        path="building"
                        lazy={() => import("./components/Administration/AdminBuilding")}
                    />
                    <Route
                        path="users"
                        lazy={() => import("./components/Administration/AdminUsers")}
                    />
                    <Route
                        path="units/:unitUUID?"
                        lazy={() =>
                            import(
                                "./components/Administration/List/AdminTemporaryUnitsAndParkings"
                            )
                        }
                    />
                    <Route
                        path="lockers/:lockerUUID?"
                        lazy={() => import("./components/Administration/List/AdminListLockers")}
                    />
                    <Route
                        path="parkings/:parkingUUID?"
                        lazy={() =>
                            import(
                                "./components/Administration/List/AdminTemporaryUnitsAndParkings"
                            )
                        }
                    />
                    <Route
                        path="vote-shares"
                        lazy={() => import("./components/Administration/VoteShares")}
                    />
                </Route>
                <Route path="*" lazy={() => import("./components/Other/PageNotFound")} />
            </Route>
            <Route path="*" lazy={() => import("./components/Other/PageNotFound")} />
        </Route>
    )
)

export default function App() {
    try {
        return <RouterProvider router={router} />
    } catch (e) {
        return <ErrorElement error={e} />
    }
}
