import { SimpleTKey } from "./translate"

/** An object mapping error codes to translation keys.  */
export const errorCodesMapping: { [key in string]: SimpleTKey | undefined } = {
    "functions/unauthenticated":
        "auth_issue_try_logging_out_and_logging_back_in_or_make_sure_you_have_adequate_permissions",
    "auth/invalid-email": "invalid_email_please_try_again",
    "auth/invalid-credentials": "invalid_password_please_try_again",
    "auth/invalid-credential": "invalid_password_please_try_again",
    "auth/user-not-found": "no_corresponding_user_with_this_email_found",
    "auth/weak-password": "invalid_password_please_try_again",
    "auth/wrong-password": "the_entered_password_is_not_correct",
    "auth/too-many-requests":
        "too_many_requests_were_recently_made_for_this_account_please_wait_a_bit_before_trying_again",
    "auth/user-disabled": "account_disabled",
    "auth/unverified-email": "please_confirm_your_email_before_logging_in",
    "auth/network-request-failed": "not_connected_to_internet",
    not_connected_to_internet: "not_connected_to_internet",
}
