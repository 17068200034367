import { toDinero, safeDineroFactory } from "./dineroExtensions"
import { DineroStorable } from "../@appnflat-types/Common"
import { Currency, Dinero } from "dinero.js"
import { DateTime, ISOYearMonth } from "./dates"
import { objectEntries } from "./objects"

/**
 * Given a dictionary of amounts by date, it sums all the amounts and returns the sum.
 *
 * @param amountsByDate The dictionary of amounts by date.
 * @param currency The currency of the amounts.
 * @param startDate The start date of the period to sum.
 * @param endDate The end date of the period to sum.
 */
export function sumAmountsByDate(
    amountsByDate: Record<ISOYearMonth, DineroStorable | undefined> | undefined,
    currency: Currency | undefined,
    { startDate, endDate }: { startDate?: DateTime; endDate?: DateTime } = {}
): Dinero {
    try {
        if (!amountsByDate) return toDinero(0, currency)
        return (
            objectEntries(amountsByDate)
                .map(([date, amount]) => {
                    const dateObj = new DateTime(date)
                    if ((startDate && dateObj < startDate) || (endDate && dateObj > endDate))
                        return null
                    return amount
                })
                .reduce(
                    (acc, amount) => acc.add(safeDineroFactory(amount, currency)),
                    toDinero(0, currency)
                ) ?? toDinero(0, currency)
        )
    } catch {
        return toDinero(0, currency)
    }
}
