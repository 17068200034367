import React from "react"
import { Title, Button, Container, Group, Text } from "@mantine/core"
import * as classes from "./NoMobile.module.css"
import { useAppTranslation } from "hooks/hooks"
import { useNavigate } from "react-router"

export function NoMobile() {
    const t = useAppTranslation()
    const navigate = useNavigate()
    return (
        <Container className={classes.root}>
            <div className={classes.label}>{t("core:coming_soon")}</div>
            <Title className={classes.title}>{t("core:mobile_not_yet_supported")}</Title>
            <Text className={classes.description}>
                {t("core:please_use_on_a_compouter_for_now")}
            </Text>
            <Group justify="center">
                <Button variant="subtle" size="md" onClick={() => navigate("/")}>
                    {t("core:go_back_home")}
                </Button>
            </Group>
        </Container>
    )
}
