import { z, ZodTypeAny } from "zod"
import { RecursiveKeysOfUnion } from "./helpers"

type AddOpenAPIParams = {
    type?: "string"
    pattern?: string
    maxLength?: number
    minLength?: number
}

/** Calls the `.openapi()` function on a zod object if it exists, skips calling it otherwise. */
export function addOpenAPI<Z extends z.ZodType>(toExtend: Z, params: AddOpenAPIParams): Z {
    return "openapi" in toExtend && typeof toExtend.openapi === "function" ?
            toExtend.openapi(params)
        :   toExtend
}

/**
 * Creates a custom zod object with regex that supports both the OpenAPI field and a template
 * literal custom TypeScript type.
 *
 * @example
 * // Creates a zod object with type `unit:${number}`. When used in an OpenAPI schema, it will
 * // have the following properties:
 * // - type: "string"
 * // - pattern: "^unit:[0-9]+$"
 * zodRegex<`unit:${number}`>(/^unit:[0-9]+$/)
 */
export function zodRegex<TemplateLiteral extends string>(
    pattern: RegExp,
    { message, max, min }: { message?: string; max?: number; min?: number } = {}
) {
    const zodType = z
        .custom<TemplateLiteral>(
            (v) =>
                typeof v === "string" &&
                pattern.test(v) &&
                (!max || v.length <= max) &&
                (!min || v.length >= min),
            { message }
        )
        .and(z.string())

    return addOpenAPI(zodType, {
        type: "string",
        maxLength: max,
        minLength: min,
        pattern: pattern.source,
    })
}

/** Returns the maximum and minimum values for a field. */
export function limitsOfField<T extends ZodTypeAny>(
    schema: T,
    field: RecursiveKeysOfUnion<z.infer<T>>
) {
    try {
        // console.log(`In limitsOfField for field=${field}`)
        const indexOfDot = field.indexOf(".")
        const childField = indexOfDot < 0 ? undefined : field.substring(indexOfDot + 1)

        if (!schema || typeof schema !== "object" || !("shape" in schema) || !schema.shape) {
            return { min: undefined, max: undefined }
        }

        if (childField) {
            const parentField = (indexOfDot === -1 ? field : (
                field.slice(0, indexOfDot)
            )) as any as keyof typeof schema.shape
            let shape = schema.shape[parentField] as ZodTypeAny | undefined
            if ("unwrap" in schema && schema.unwrap && typeof schema.unwrap === "function") {
                shape = schema.unwrap().shape[parentField]
                // console.log("Unwrapped schema of parent", shape)
            }
            if (!shape) return { min: undefined, max: undefined }
            return limitsOfField<any>(shape, childField)
        }

        // console.log("Schema", schema, schema.shape)

        let shape = schema.shape[field as any as keyof typeof schema.shape] as
            | ZodTypeAny
            | undefined

        if (
            shape &&
            typeof shape === "object" &&
            "unwrap" in shape &&
            shape.unwrap &&
            typeof shape.unwrap === "function"
        ) {
            shape = shape.unwrap()
            // console.log("Unwrapped shape", shape)
        }

        if (
            !shape ||
            !shape._def ||
            !("checks" in shape._def) ||
            !shape._def.checks ||
            !Array.isArray(shape._def.checks)
        ) {
            return { min: undefined, max: undefined }
        }

        const min: number | undefined = shape._def.checks.find(
            (check: unknown) =>
                check && typeof check === "object" && "kind" in check && check.kind === "min"
        )?.value
        const max: number | undefined = shape._def.checks.find(
            (check: unknown) =>
                check && typeof check === "object" && "kind" in check && check.kind === "max"
        )?.value

        return { min, max }
    } catch (error) {
        console.error("Error in limitsOfField", error)
        return { min: undefined, max: undefined }
    }
}

/** Returns whether a field is required or not. */
export function isRequiredField<T extends ZodTypeAny>(schema: T, field: string | number | symbol) {
    try {
        if (!("shape" in schema) || !schema.shape) return !(schema instanceof z.ZodOptional)
        if (!schema.shape[field as any as keyof typeof schema.shape]) return false
        const subfield = String(field).substring(String(field).indexOf("_") + 1)
        if (subfield)
            return isRequiredField(
                schema.shape[subfield as any as keyof typeof schema.shape],
                subfield
            )
        // @ts-ignore
        return !(schema.shape[field] instanceof z.ZodOptional)
    } catch (error) {
        console.error("Error in isRequiredField", error)
        return false
    }
}
