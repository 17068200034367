import { cachedBuildingSelector } from "store/cacheSelectors"
import { useAppParams, useAppSelector } from "./hooks"

export function useBuilding() {
    const { buildingRef } = useAppParams()
    const building = useAppSelector((s) => cachedBuildingSelector(s, buildingRef))
    return building
}

export function useCurrency() {
    const building = useBuilding()
    return building?.currency ?? "CAD"
}
