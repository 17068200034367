/**
 * Reads in a file and outputs the content of the file as a base 64 string.
 */
export default function convertBase64(file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)
        fileReader.onload = () => resolve(fileReader.result ?? "")
        fileReader.onerror = (error) => reject(error)
    })
}
