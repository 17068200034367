import { Currency, Dinero } from "dinero.js"
import { Account } from "../@appnflat-types/Common"
import { sumAmountsByDate } from "./sumAmountsByDate"
import { absoluteValue, safeDineroFactory, toDinero } from "./dineroExtensions"
import { DateTime } from "./dates"

/** The method used to calculate an account's balance. */
export enum BalanceCalculationMethod {
    /** `unit`: balance = due to syndicate = starting balance + debits (≈invoiced by syndicate) - credits (≈paid by unit) */
    unit,
    /** `supplier`: balance = due to supplier = starting balance + credits (≈invoiced by supplier) - debits (≈paid by syndicate) */
    supplier,
    /** `bankOperatingAccount`: Show the bank balance as the inverse of how it would appear on a bank statement, which is how it is recorded in our system. balance = starting balance + credits - debits */
    bankOperatingAccount,
    /** `bankStatement`: Show the bank balance as it would appear on a bank statement. balance = -1 * (starting balance + credits - debits) */
    bankStatement,
    /** `categoryExpense`: balance = starting balance + credit - debit */
    categoryExpense,
    /** `categoryRevenue`: balance = starting balance + debit - credit */
    categoryRevenue,
    /** `SB+D-C`: balance = starting balance + debits - credits */
    SBPlusDMinusC,
    /** `SB+C-D`: balance = starting balance + credits - debits */
    SBPlusCMinusD,
    /** `D-C`: balance = debits - credits */
    DMinusC,
    /** `C-D`: balance = credits - debits */
    CMinusD,
}

/**
 * Calculates the balance of an account.
 *
 * @param method The method used to calculate the balance. {@link BalanceCalculationMethod}
 */
export function calculateBalance(
    obj: Pick<Account, "startingBalance" | "credits" | "debits">,
    currency: Currency | undefined,
    method: BalanceCalculationMethod,
    { startDate, endDate }: { startDate?: DateTime; endDate?: DateTime } = {}
): Dinero {
    const sb = safeDineroFactory(obj.startingBalance, currency)
    const ignoreSB =
        method === BalanceCalculationMethod.DMinusC || method === BalanceCalculationMethod.CMinusD
    const debits = sumAmountsByDate(obj.debits, currency, {
        startDate: ignoreSB ? startDate : undefined,
        endDate,
    })
    const credits = sumAmountsByDate(obj.credits, currency, {
        startDate: ignoreSB ? startDate : undefined,
        endDate,
    })

    let balance = toDinero(0, currency)

    if (method === BalanceCalculationMethod.DMinusC) {
        balance = debits.subtract(credits)
    } else if (method === BalanceCalculationMethod.CMinusD) {
        balance = credits.subtract(debits)
    } else if (
        method === BalanceCalculationMethod.unit ||
        method === BalanceCalculationMethod.categoryRevenue ||
        method === BalanceCalculationMethod.SBPlusDMinusC
    ) {
        balance = sb.add(debits).subtract(credits)
    } else {
        balance = sb.add(credits).subtract(debits)
    }

    if (method === BalanceCalculationMethod.bankStatement) balance = balance.multiply(-1)

    if (balance.isZero()) return absoluteValue(balance)
    return balance
}
