import { Role } from "@constants/Role"
import {
    doc,
    query,
    onSnapshot,
    collection,
    collectionGroup,
    where,
    QueryFieldFilterConstraint,
} from "firebase/firestore"
import { db } from "firebaseSetup"
import { Collection } from "@appnflat-types/Collection"
import {
    emptyCacheOfBuildingData,
    setBuildingFromServer,
    setCollectionFromServer,
} from "store/cache"
import { buildingUserSchema } from "@appnflat-types/BuildingUser"
import { Building, buildingSchema } from "@appnflat-types/Building"
import { idForCollection } from "@shared/idForCollection"
import { useAppDispatch, useAppParams, useAppSelector, usePermissions } from "./hooks"
import {
    setDataLoadingState,
    setUserDocInBuildings,
    userDocsInBuildingsSelector,
    userSelector,
    userUIDSelector,
} from "store/appState"
import { useEffect } from "react"
import { useBuilding } from "./useBuilding"
import { sortEntries } from "./useFirestore/sortEntries"
import { objectEntries } from "@shared/objects"

/** The list of collections that should be loaded for a building when the user is on that building. */
const collectionsToLoadForBuilding = [
    "units" as const,
    "people" as const,
    "suppliers" as const,
    "parkings" as const,
    "lockers" as const,
    "banks" as const,
    "penalties" as const,
    "categories" as const,
]

function collectionsToLoad(
    userRole: Role,
    ownersFinancesAccess: Building["ownersFinancesAccess"] | undefined
): (Collection & (typeof collectionsToLoadForBuilding)[number])[] {
    function filterCollectionsToLoadInternal(coll: (typeof collectionsToLoadForBuilding)[number]) {
        switch (userRole) {
            case Role.resident:
                return coll === "people"
            case Role.owner:
                return (
                    coll !== "penalties" &&
                    (coll === "banks" || coll === "suppliers" || coll === "categories") &&
                    ownersFinancesAccess === "all"
                )
            default:
                return true
        }
    }
    return collectionsToLoadForBuilding.filter(filterCollectionsToLoadInternal)
}

/**
 * Loads the building's collections from Firestore.
 *
 * @param buildingDataLoadAllowed Whether the building's data should be loaded or not.
 */
function useFirestoreLoadBuildingCollections(buildingDataLoadAllowed: boolean) {
    const { buildingRef, fiscalYear } = useAppParams()
    const { userRole } = usePermissions()
    const ownersFinancesAccess = useBuilding()?.ownersFinancesAccess
    const userEmail = useAppSelector(userSelector)?.email
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (
            !fiscalYear ||
            !buildingRef ||
            !buildingDataLoadAllowed ||
            !userEmail ||
            !userRole ||
            !ownersFinancesAccess
        ) {
            dispatch(setDataLoadingState({ currentBuilding: false }))
            return () => {
                dispatch(setDataLoadingState({ currentBuilding: true }))
            }
        }
        const collections = collectionsToLoad(userRole, ownersFinancesAccess)
        const collectionsRemainingToLoad = new Set(collections)

        const unsubscribes: (() => void)[] = []

        for (let i = 0, n = collections.length; i < n; i++) {
            const coll = collections[i]
            if (!coll) continue
            const filterWithFiscalYear = coll !== "penalties" // && coll !== "users" && coll !== "invitedUsers"
            const filters = [
                filterWithFiscalYear && where("fiscalYear", "==", fiscalYear),
                userRole === Role.owner &&
                    coll === "units" &&
                    where("ownersEmails", "array-contains", userEmail),
                (userRole === Role.owner || userRole === Role.resident) &&
                    coll === "people" &&
                    where("emails", "array-contains", userEmail),
            ].filter((f): f is QueryFieldFilterConstraint => f !== false)
            console.debug(
                `Subscribing to collection ${coll} for building ${buildingRef} with filters ${JSON.stringify(
                    filters
                )}`
            )
            unsubscribes.push(
                onSnapshot(
                    query(collection(db, "buildings", buildingRef, coll), ...filters),
                    function collectionSnapshotReceivedHandler(snap) {
                        collectionsRemainingToLoad.delete(coll)
                        if (collectionsRemainingToLoad.size === 0) {
                            console.log("All collections loaded")
                            dispatch(setDataLoadingState({ currentBuilding: false }))
                        }
                        const localObjects: Record<string, any>[] = []
                        for (let i = 0, n = snap.docs.length; i < n; i++) {
                            const data = snap.docs[i]?.data()
                            if (!data || (filterWithFiscalYear && data.fiscalYear !== fiscalYear))
                                continue
                            localObjects.push(data)
                        }
                        console.debug(
                            `Saving ${localObjects.length} / ${snap.docs.length} docs for ${coll} to cache`
                        )
                        // setCacheCollection({ [coll]: localObjects })
                        const values = sortEntries(coll, localObjects as any)
                        if (values) {
                            const alreadySeenIds = new Set<string>()
                            // Only push unique documents to the store
                            const uniqueValues = values.filter((value) => {
                                // @ts-ignore
                                const id = idForCollection({ value, collection })
                                if (!id || alreadySeenIds.has(id)) return false
                                else {
                                    alreadySeenIds.add(id)
                                    return true
                                }
                            })
                            dispatch(
                                setCollectionFromServer({
                                    removeAllCurrentValues: true,
                                    collection: coll,
                                    // @ts-ignore
                                    values: uniqueValues,
                                })
                            )
                        }
                    },
                    (error) => {
                        collectionsRemainingToLoad.delete(coll)
                        if (collectionsRemainingToLoad.size === 0) {
                            console.log("All collections loaded")
                            dispatch(setDataLoadingState({ currentBuilding: false }))
                        }
                        console.error(
                            `Error in useFirestoreLoadBuildingCollections for '${coll}'`,
                            error
                        )
                    }
                )
            )
        }
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe())
            dispatch(emptyCacheOfBuildingData())
            dispatch(setDataLoadingState({ currentBuilding: true }))
        }
    }, [
        buildingRef,
        fiscalYear,
        ownersFinancesAccess,
        userEmail,
        userRole,
        buildingDataLoadAllowed,
        dispatch,
    ])
}

/** Loads the building docs for all buidlings the user has access to. */
function useFirestoreLoadBuildingDocs() {
    const userDocsInBuildings = useAppSelector(userDocsInBuildingsSelector)
    const dispatch = useAppDispatch()
    useEffect(() => {
        const unsubscribes: (() => void)[] = []
        const userDocs = objectEntries(userDocsInBuildings)
        const buildingsRemainingToLoad = new Set(userDocs.map(([b]) => b))
        for (let i = 0, n = userDocs.length; i < n; i++) {
            const userDoc = userDocs[i]
            if (!userDoc) continue
            const [buildingRef, user] = userDoc
            console.log(`Subscribing to building doc ${buildingRef}`)
            unsubscribes.push(
                onSnapshot(
                    doc(db, "buildings", buildingRef),
                    function buildingSnapshotReceivedHandler(snap) {
                        buildingsRemainingToLoad.delete(buildingRef)
                        if (buildingsRemainingToLoad.size === 0) {
                            console.log("All building docs loaded")
                            dispatch(setDataLoadingState({ buildings: false }))
                        }
                        const data = buildingSchema.safeParse(snap.data())
                        if (!data.success) {
                            console.log(`Error parsing building doc for ${buildingRef}`, data.error)
                            return
                        }
                        console.log(`Received building doc for ${buildingRef}`)
                        dispatch(
                            setBuildingFromServer({ building: data.data, ref: buildingRef, user })
                        )
                    },
                    (error) => {
                        buildingsRemainingToLoad.delete(buildingRef)
                        if (buildingsRemainingToLoad.size === 0) {
                            console.log("All building docs loaded")
                            dispatch(setDataLoadingState({ buildings: false }))
                        }
                        console.error(
                            `Error in useFirestoreLoadBuildingDocs for ${buildingRef}`,
                            error
                        )
                    }
                )
            )
        }
        return () => {
            unsubscribes.forEach((unsubscribe) => unsubscribe())
        }
    }, [userDocsInBuildings, dispatch])
}

/** Fetches all user documents for the current user.
 *
 * This gives us a list of the buildings they are in and their role in each building.
 */
function useFirestoreLoadUserDocs() {
    const userUID = useAppSelector(userUIDSelector)
    const dispatch = useAppDispatch()
    useEffect(() => {
        if (!userUID) return
        console.info("useFirestoreLoadUserDocs called with ", userUID)
        return onSnapshot(
            query(collectionGroup(db, "users"), where("uid", "==", userUID)),
            function userSnapshotReceivedHandler(snap) {
                const buildingUserDocs = snap.docs
                    .map((doc) => {
                        const buildingUserParse = buildingUserSchema.safeParse(doc.data())
                        const buildingRef = doc.ref.parent.parent?.id
                        // This to ensure we ignore any user documents that are not in the buildings
                        // collection (i.e., those in the buildingsBackup collection)
                        const rootCollection = doc.ref.parent.parent?.parent?.id
                        if (!buildingUserParse.success) {
                            console.error(
                                "Error parsing a user document:",
                                buildingUserParse.error,
                                doc.data()
                            )
                        } else if (
                            buildingUserParse.success &&
                            buildingRef &&
                            rootCollection === "buildings"
                        ) {
                            console.log(`Received user document for ${buildingRef}`)
                            return { user: buildingUserParse.data, buildingRef }
                        } else {
                            console.log(
                                `Ignoring user document in ${rootCollection} for ${buildingRef}`
                            )
                        }
                    })
                    .filter((data): data is Exclude<typeof data, undefined> => !!data)
                dispatch(setUserDocInBuildings(buildingUserDocs))
            },
            (error) => console.error(`Error in useFirestoreLoadUserDocs`, error)
        )
    }, [dispatch, userUID])
}

/** Subscribes to the data the app needs in Firestore. */
export function useFirestore(buildingDataLoadAllowed: boolean) {
    useFirestoreLoadUserDocs()
    useFirestoreLoadBuildingDocs()
    useFirestoreLoadBuildingCollections(buildingDataLoadAllowed)
}
