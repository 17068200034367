import { z } from "zod"
import { DistributiveOmit } from "../@appnflat-types/helpers"
import { buildingRefString, uidString, uuidString } from "../@appnflat-types/BaseStrings"
import { isoDateSchema } from "./dates"
import { ParsingErrors } from "../@appnflat-types/parsingErrors"

const fileIDSchema = z.string().refine((v) => !v.includes("/"), {
    message: ParsingErrors.file_name_cannot_contain_slash,
})

/** The parameters required to determine the path of a file. */
export const filePathParamsSchema = z.union([
    z.object({
        /** - `type = "insuranceProof" | "waterHeater"`: A file that is either an insurance
         *    certificate or a water heater certificate for a given unit.
         */
        type: z.union([z.literal("insuranceProof"), z.literal("waterHeater")]),
        /** The uuid of the associated unit. */
        uuid: uuidString,
        /** The file extension. Should __NOT__ start with a `.`.
         * @example "pdf"
         */
        extension: z.string(),
        buildingRef: buildingRefString,
    }),
    z.object({
        /** - `type = "transactions"`: A file that is a bill image associated to a given transaction. */
        type: z.literal("transactions"),
        /** The uuid of the associated transaction. */
        transactionUUID: uuidString,
        /** The file id, with its extension. */
        fileID: fileIDSchema,
        buildingRef: buildingRefString,
    }),
    z.object({
        /** - `type = "postAttachment"`: An attachment in a post. */
        type: z.literal("postAttachment"),
        /** The uuid of the post. */
        postUUID: uuidString,
        /** The file id, with its extension. */
        fileID: fileIDSchema,
        buildingRef: buildingRefString,
    }),
    z.object({
        /** - `type = "emailAttachment"`: An attachment in an email. */
        type: z.literal("emailAttachment"),
        /** The uuid of the email. */
        emailUUID: uuidString,
        /** The file id, with its extension. */
        fileID: fileIDSchema,
        buildingRef: buildingRefString,
    }),
    z.object({
        /** - `type = "email"`: An email, currently only for requests. */
        type: z.literal("email"),
        /** The uuid of the email. */
        emailUUID: uuidString,
        buildingRef: buildingRefString,
    }),
    z.object({
        /** - `type = "signature"`: A signature. */
        type: z.literal("signature"),
        /** The user's uid. */
        userUID: uidString,
    }),
    z.object({
        /** - `type = "unknownEmail"`: An email that could not be assigned to a specific building. */
        type: z.literal("unknownEmail"),
        /** A unique identifier for the email. */
        uuid: uuidString,
    }),
    z.object({
        /** - `type = "bankReconciliation"`: A pdf for a bank reconciliation period. */
        type: z.literal("bankReconciliation"),
        /** The aid of the bank. */
        aid: z.string(),
        /** The date of the bank reconciliation, as an ISO string (`YYYY-MM-DD`). */
        date: isoDateSchema,
        buildingRef: buildingRefString,
    }),
])
/** The parameters required to determine the path of a file, without the property `buildingRef`.
 * Note: most times, you should be using {@link FilePathParams} instead. */
export type CoreFilePathParams = DistributiveOmit<
    z.infer<typeof filePathParamsSchema>,
    "buildingRef"
>

/** The parameters required to determine the path of a file. */
export type FilePathParams = z.infer<typeof filePathParamsSchema>

/** Returns the path for a certain file. */
export function pathForFile(p: FilePathParams): {
    /** The full path for the file. */
    path: string
    /** The id of the file. */
    fileID: string
} {
    let path: string
    let fileID: string
    switch (p.type) {
        case "transactions":
            // Old path = `buildings/${p.buildingRef}/year/${p.fiscalYear}/${p.type}/${p.uuid}.${p.extension}`
            path = `buildings/${p.buildingRef}/transactions/${p.transactionUUID}`
            fileID = p.fileID
            break
        case "email":
            path = `buildings/${p.buildingRef}/emails/${p.emailUUID}`
            fileID = "email.eml"
            break
        case "emailAttachment":
            path = `buildings/${p.buildingRef}/emails/${p.emailUUID}/attachments`
            fileID = p.fileID
            break
        case "postAttachment":
            path = `buildings/${p.buildingRef}/posts/${p.postUUID}/attachments`
            fileID = p.fileID
            break
        case "insuranceProof":
        case "waterHeater":
            path = `buildings/${p.buildingRef}/${p.type}`
            fileID = `${p.uuid}.${p.extension}`
            break
        case "bankReconciliation":
            // Old path = `buildings/${p.buildingRef}/year/${p.fiscalYear}/bankReconciliation/${p.aid}/${p.date}.pdf`
            path = `buildings/${p.buildingRef}/bankReconciliation/${p.aid}`
            fileID = `${p.date}.pdf`
            break
        case "signature":
            path = `signatures`
            fileID = `${p.userUID}.svg`
            break
        case "unknownEmail":
            path = `unknownEmails`
            fileID = `${p.uuid}.eml`
            break
        default: {
            const exhaustiveCheck: never = p
            throw new Error(`Unhandled file type: ${exhaustiveCheck}`)
        }
    }
    return {
        path: `${path}/${fileID}`,
        fileID,
    }
}
