import { z } from "zod"
import { currencySchema } from "./Currency"
import {
    aidString,
    emailString,
    phoneString,
    shortString,
    uuidString,
    veryShortString,
} from "./BaseStrings"
import { isoYearMonthSchema } from "../@shared/dates"
import { zodRegex } from "./zodExtensions"
import { ParsingErrors } from "./parsingErrors"

/** The length of ids. */
export const ID_LENGTH = 22

export function idSchemaGenerator<T extends string>(prefix: T) {
    return zodRegex<`${T}_${string}`>(new RegExp(`^${prefix}_[a-zA-Z0-9]{${ID_LENGTH}}$`))
}

/** An object that has all values needed for Dinero to function and that can be converted to a Dinero object using DineroFactory. */
export const dineroStorableSchema = z.object({
    amount: z.number().int(),
    currency: currencySchema.optional(),
    precision: z.number().int().min(0).max(4).optional(),
    locale: veryShortString.optional(),
})
export type DineroStorable = z.infer<typeof dineroStorableSchema>

/** @see dineroStorableSchema
 *
 * Only difference is that the amount must be positive or zero.
 */
export const positiveDineroStorableSchema = dineroStorableSchema.omit({ amount: true }).extend({
    amount: z.number().int().nonnegative(),
})
/** @see dineroStorableSchema
 *
 * Only difference is that the amount must be strictly positive.
 */
export const strictlyPositiveDineroStorableSchema = dineroStorableSchema
    .omit({ amount: true })
    .extend({
        amount: z.number().int().positive(),
    })

export const attachmentSchema = z.object({
    /** The name to display to the user as the file name. */
    fileDisplayName: z.string().max(300),
    /**
     * The id of the file.
     *
     * Note that this includes the extension. This is not a user-facing value, but the id of the file in the bucket.
     */
    fileID: z.string(),
})

export const attachmentsSchema = z.array(attachmentSchema)
export type Attachments = z.infer<typeof attachmentsSchema>
export type Attachment = z.infer<typeof attachmentsSchema>[number]

export const objectWithAttachmentsSchema = (maxNumberOfAttachements: number) =>
    z.object({
        attachments: attachmentsSchema
            .max(maxNumberOfAttachements, { message: ParsingErrors.too_many_attachments })
            .optional(),
    })

export const objectWithUUIDSchema = z.object({
    uuid: uuidString,
    /** The date of the last edit. */
    lastModified: z.number().optional(),
})
export type ObjectWithUUID = z.infer<typeof objectWithUUIDSchema>

export const fiscalYearSchema = z.number().int().positive()

export const objectInFiscalYearSchema = objectWithUUIDSchema.extend({
    fiscalYear: fiscalYearSchema,
    archived: z.boolean().optional(),
})
export type ObjectInFiscalYear = z.infer<typeof objectInFiscalYearSchema>

export const emailFrequencySchema = z.enum(["never", "weekly", "monthly", "yearly"])
export type EmailFrequency = z.infer<typeof emailFrequencySchema>

export const amountsSummarySchema = z.record(isoYearMonthSchema, dineroStorableSchema)
export type AmountsSummary = z.infer<typeof amountsSummarySchema>

/** An object with an `aid` and a balance. */
export const accountSchema = objectInFiscalYearSchema.extend({
    aid: aidString,
    startingBalance: dineroStorableSchema.optional(),
    /** The keys are of the format yyyy-LL. */
    debits: amountsSummarySchema.optional(),
    /** The keys are of the format yyyy-LL. */
    credits: amountsSummarySchema.optional(),
})
export type Account = z.infer<typeof accountSchema>

export const phoneSchema = z.object({
    type: z.enum(["cell", "home", "work", "fax", "other"]),
    number: phoneString,
})
export type Phone = z.infer<typeof phoneSchema>

/** An object that can have multiple phone numbers and emails. */
export const objectWithEmailsAndPhonesSchema = z.object({
    emails: z.array(emailString.email()).optional(),
    phones: z.array(phoneSchema).optional(),
})
export type ObjectWithEmailsAndPhones = z.infer<typeof objectWithEmailsAndPhonesSchema>

/** An object with monthly fees. */
export const objectWithMonthlyFeesSchema = z.object({
    /** The monthly contributions.
     *
     * The order of this array matches that of the currently applied budget's contributions.
     * If a fee doesn't apply to the account, add a null value to keep the correct order. */
    monthlyContributions: z.array(z.union([dineroStorableSchema, z.null()])).optional(),
    /** If true, the object will be ignored when calculating monthly fees and will have no monthly
     * fees. */
    noMonthlyFees: z.boolean().optional(),
})
export type ObjectWithMonthlyFees = z.infer<typeof objectWithMonthlyFeesSchema>

/** An object that can contain encrypted bank account information. */
export const objectWithEncryptedBankAccountDetailsSchema = z.object({
    hasUploadedBankAccountDetails: z.boolean().optional(),
    /**
     * A redacted string of the bank account number.
     *
     * @example "****1234"
     */
    redactedBankAccountNumber: shortString.optional(),
})
export type ObjectWithEncryptedBankAccountDetails = z.infer<
    typeof objectWithEncryptedBankAccountDetailsSchema
>

/** A number that represents a day of the month (i.e., 1-31). */
export const dayOfMonthSchema = z.union([
    z.literal(1),
    z.literal(2),
    z.literal(3),
    z.literal(4),
    z.literal(5),
    z.literal(6),
    z.literal(7),
    z.literal(8),
    z.literal(9),
    z.literal(10),
    z.literal(11),
    z.literal(12),
    z.literal(13),
    z.literal(14),
    z.literal(15),
    z.literal(16),
    z.literal(17),
    z.literal(18),
    z.literal(19),
    z.literal(20),
    z.literal(21),
    z.literal(22),
    z.literal(23),
    z.literal(24),
    z.literal(25),
    z.literal(26),
    z.literal(27),
    z.literal(28),
    z.literal(29),
    z.literal(30),
    z.literal(31),
])
/** A number that represents a day of the month (i.e., 1-31). */
export type DayOfMonth = z.infer<typeof dayOfMonthSchema>
