import about from "./about"
import communication from "./communication"
import core from "./core"
import messages from "./messages"
import report from "./report"

export default {
    about,
    communication,
    core,
    messages,
    report,
}
