export enum ParsingErrors {
    too_many_attachments = "too_many_attachments",
    /** For forms where the user must agree to terms. */
    you_must_agree = "you_must_agree",
    must_be_in_the_past = "must_be_in_the_past",
    must_be_3_digits = "must_be_3_digits",
    must_be_5_digits = "must_be_5_digits",
    must_only_contain_digits = "must_only_contain_digits",
    invalid_email_address = "invalid_email_address",
    should_be_decimal_positive_number = "should_be_decimal_positive_number",
    must_be_at_least_5_digits = "must_be_at_least_5_digits",
    must_be_less_than_20_digits = "must_be_less_than_20_digits",
    must_be_positive = "must_be_positive",
    must_be_less_than_100 = "must_be_less_than_100",
    // each_subtransaction_should_have_unique_aid = "each_subtransaction_should_have_unique_aid",
    cannot_contain_space_slash_or_hashtag = "cannot_contain_space_slash_or_hashtag",
    some_subtransaction_should_have_non_null_amount = "some_subtransaction_should_have_non_null_amount",
    subtransactions_should_balance_out_to_zero = "subtransactions_should_balance_out_to_zero",
    passwords_dont_match = "passwords_dont_match",
    unknown_error = "unknown_error",
    account_is_missing = "account_is_missing",
    invalid_hex_color_code = "invalid_hex_color_code",
    file_name_cannot_contain_slash = "file_name_cannot_contain_slash",
}

export const ParsingErrorsFR: { [key in ParsingErrors]: string } = {
    too_many_attachments: "Trop de fichiers joints",
    you_must_agree: "Vous devez accepter",
    must_be_in_the_past: "Doit être dans le passé",
    must_be_3_digits: "Doit contenir 3 chiffres",
    must_be_5_digits: "Doit contenir 5 chiffres",
    must_only_contain_digits: "Doit contenir uniquement des chiffres",
    invalid_email_address: "Adresse email invalide",
    should_be_decimal_positive_number: "Entrez un nombre décimal positif",
    must_be_at_least_5_digits: "Doit contenir au moins 5 chiffres",
    must_be_less_than_20_digits: "Doit contenir moins de 20 chiffres",
    must_be_positive: "Doit être positif",
    must_be_less_than_100: "Doit être inférieur à 100%",
    // each_subtransaction_should_have_unique_aid:
    //     "Chaque sous-transaction devrait être avec un compte différent",
    cannot_contain_space_slash_or_hashtag:
        "Ne doit pas contenir d'espaces, de barres obliques ou de mot-dièse",
    some_subtransaction_should_have_non_null_amount: "Un des montants doit être non-nul",
    subtransactions_should_balance_out_to_zero:
        "La somme de tous les montants doit être égal à zéro",
    passwords_dont_match: "Les mots de passes ne sont pas les mêmes",
    unknown_error: "Erreur inconnue",
    account_is_missing: "Compte manquant",
    invalid_hex_color_code: "Couleur invalide",
    file_name_cannot_contain_slash: "Le nom du fichier ne peut pas contenir de barre oblique",
}

export const ParsingErrorsEN: { [key in ParsingErrors]: string } = {
    too_many_attachments: "Too many files attached",
    you_must_agree: "You must agree",
    must_be_in_the_past: "Must be in the past",
    must_be_3_digits: "Must be 3 digits",
    must_be_5_digits: "Must be 5 digits",
    must_only_contain_digits: "Must only contain digits",
    invalid_email_address: "Invalid email address",
    should_be_decimal_positive_number: "Should be a decimal positive number",
    must_be_at_least_5_digits: "Must be at least 5 digits",
    must_be_less_than_20_digits: "Must be less than 20 digits",
    must_be_positive: "Must be positive",
    must_be_less_than_100: "Must be less than 100%",
    // each_subtransaction_should_have_unique_aid:
    //     "Each subtransaction should be with a different account",
    cannot_contain_space_slash_or_hashtag: "Cannot contain spaces, slashes or pound signs",
    some_subtransaction_should_have_non_null_amount: "One of the amounts must be non-null",
    subtransactions_should_balance_out_to_zero: "The sum of all amounts should equal zero",
    passwords_dont_match: "The passwords do not match",
    unknown_error: "Unknown error",
    account_is_missing: "Account is missing",
    invalid_hex_color_code: "Invalid color",
    file_name_cannot_contain_slash: "File name cannot contain a slash",
}

function languageToParsingErrorEnum(language: string | undefined) {
    if (!language || language.startsWith("en")) return ParsingErrorsEN
    else if (language.startsWith("fr")) return ParsingErrorsFR
    else return ParsingErrorsEN
}

function isParsingErrors(key: ParsingErrors | string | undefined): key is ParsingErrors {
    return !!key && Object.keys(ParsingErrors).includes(key)
}

export function parsingErrorLocale(
    key: ParsingErrors | string | undefined,
    language: string | undefined
) {
    if (!isParsingErrors(key)) return languageToParsingErrorEnum(language).unknown_error
    else return languageToParsingErrorEnum(language)[key]
}
