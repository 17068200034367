const translations = {
    fr: {
        problem_edit_transaction: "Une erreur est survenue lors de l'édition de la transaction.",
        unable_to_add_reverse_check: "Impossible de retourner le chèque",
        "unable_to_add_reverse_wire-transfer": "Impossible de retourner le paiement électronique",
        error_deleting_signature: "Une erreur est survenue lors de la suppression de la signature.",
        error_saving_signature: "Une erreur est survenue lors de la sauvegarde de la signature.",
        error_archiving_email: "Une erreur est survenue en archivant le courriel.",
        error_assigning_email_to_request:
            "Une erreur est survenue en assignant le courriel à la requête.",
        pending_transaction:
            "Il y a des transactions en attente avant le début de la nouvelle année fiscale.",
        new_year_not_after_most_recent_year:
            "La nouvelle année fiscale doit être après la plus récente.",
        account_created: "Compte créé",
        your_account_has_been_created_please_sign_in:
            "Votre compte a été créé. Veuillez vous connecter.",
        request_closed: "La requête est déjà fermée.",
        select_bills_to_pay: "sélectionner les factures à payer",
        error_merging_requests: "Erreur lors de la fusion des requêtes",
        problem_printing_check: "Erreur lors de l'impression du chèque",
        date_and_check_fields_required: "La date et le numéro de chèque manquent",
        now: "Il y a quelques instants",
        yesterday: "Hier",
        error: "Erreur",
        success: "Succès",
        unknown_error_occurred: "Une erreur inconnue est survenue",
        multiple: "Multiple",
        no_transactions_to_send_to_otonom: "Aucune transaction à envoyer à Otonom Solution",
        otonom_paid: "Otonom - Envoyé",
        otonom_cancelled: "Otonom - Annulé",
        otonom_readyToSendToOtonom: "Otonom - Prêt à envoyer",
        otonom_waitingApproval: "Otonom - Attente d'approbation",
        otonom_error: "Otonom - Erreur",
        check: "Chèque",
        "credit-card": "Carte de crédit",
        "debit-card": "Carte de débit",
        otonom: "Otonom Solution",
        "wire-transfer": "Virement",
        cash: "Cash",
        floor_NUMBER: "Étage {{number}}",
        unit_k: "Unité {{number}}",
        problem_signing_in: "Un problème est survenu lors de la connexion.",
        problem_verifying_email: "Une erreur s'est produite lors de la vérification du courriel.",
        problem_recovering_email: "Une erreur s'est produite lors du rétablissement du courriel.",
        problem_uploading_file: "Un problème est survenu lors du téléchargement du fichier.",
        file_uploaded: "Fichier téléchargé",
        auth_issue_try_logging_out_and_logging_back_in_or_make_sure_you_have_adequate_permissions:
            "Problème d'autorisation. Veuillez vous déconnecter et vous reconnecter ou assurez-vous d'avoir les autorisations nécessaires auprès de votre administrateur.",
        building_not_found: "Cet immeuble n'existe pas.",
        unimplemented: "Non mis en oeuvre",
        object_invalid: "Objet invalide",
        object_not_found: "Objet non trouvé",
        object_archived: "Objet archivé",
        person_cannot_be_archived_as_still_associated_to_a_unit:
            "La personne ne peut être archivée car elle est associée à une unité.",
        locker_cannot_be_archived_as_still_associated_to_a_unit:
            "Le casier ne peut être archivée car il est associé à une unité.",
        parking_cannot_be_archived_as_still_associated_to_a_unit:
            "Le parking ne peut être archivé car il est associée à une unité.",
        maximum_number_of_unpaid_balances_templates_is_4:
            "Vous pouvez définir au maximum 4 emails types pour la relance suite à des frais de condo impayés.",
        invalid_post_author: "Auteur invalide",
        deleting_post_posted_by_other_user:
            "Suppression des messages postés par d'autre utilisateurs.",
        default_penalty_cannot_be_created: "La pénalité par défaut ne peut être créée.",
        default_penalty_cannot_be_deleted: "La pénalité par défaut ne peut être supprimée.",
        other_unit_already_has_locker: "Le casier est déjà associé à une autre unité.",
        other_unit_already_has_parking: "Le parking est déjà associé à une autre unité.",
        transaction_of_wrong_type_cannot_be_approved:
            "Une transaction de ce type ne peut être approuvée.",
        an_invalid_role_was_assigned_to_this_user: "Cet utilisateur a un profil inadéquat.",
        unit_not_found: "Cette unité n'existe pas.",
        this_unit_can_no_longer_be_changed_as_it_is_archived:
            "Cette unité ne peut être modifiée car elle est archivée.",
        bank_not_found: "La Banque n'a pas été trouvée.",
        unit_not_created_as_it_already_exists: "L'unité n'a pas été créée car elle existe déjà.",
        bank_not_saved_as_it_is_invalid:
            "La banque n'est pas valide, veuillez contacter le support.",
        unit_not_saved_as_it_is_invalid: "L'unité n'est pas valide, veuillez contacter le support.",
        transaction_already_paid_cannot_be_changed:
            "Les transactions déjà payées ne peuvent être modifiées.",
        vote_share_total_is_not_100: "La somme des droits de vote n'est pas égale à 100%.",
        the_vote_shares_is_negative_which_is_prohibited:
            "Il n'est possible d'avoir un droit de vote négatif.",
        institution_number_should_be_3_digits: "Le numéro d'institution devrait avoir 3 chiffres",
        transit_number_should_be_5_digits: "Le numéro de transit devrait avoir 5 chiffres",
        account_number_should_be_5_to_20_digits:
            "Le numéro de compte devrait avoir ente 5 et 20 chiffres",
        encrypt_error: "Erreur d'encryption",
        decrypt_error: "Erreur de décryptage",
        person_is_still_an_owner_or_resident_of_a_unit:
            "Cette personne est encore propriétaire ou locataire d'une unité.",
        transaction_had_duplicate_associated_bill:
            "La facture associée à la transaction est en double.",
        error_connecting_to_otonom_ensure_payee_details_are_saved_with_them:
            "Une erreur s'est produite lors de la connection avec Otonom Solution. Assurez-vous que vos comptes bénéficiaires ont les informations nécessaires pour effectuer les paiements.",
        month_not_reconciled_as_difference_not_zero:
            "Les transactions bancaires ne sont pas conciliées car l'écart n'est pas nul.",
        invalid_otonom_id_for_every_account:
            "L'identifiant Otonom Solution est invalide pour tous les comptes.",
        no_account_has_information: "Aucun compte ne dispose d'information.",
        missing_bank_account_information_for_accounts:
            "Les coordonnées des comptes en banque n'existent pas.",
        submission_to_otonom_failed: "L'envoi à Otonom Solution a échoué",
        otonom_file_not_found: "Le fichier Otonom Solution est introuvable.",
        lock_already_in_place:
            "Un autre utilisateur est en train de travailler sur le même sujet, veuillez ré-essayer dans quelques minutes.",
        unable_to_place_lock:
            "Veuillez ré-essayer dans quelques minutes. Contactez le support si le problème persiste.",
        invalid_email_please_try_again: "Courriel invalide, Veuillez ré-essayer.",
        no_corresponding_user_with_this_email_found:
            "Aucun utilisateur ne correspond à ce courriel.",
        invalid_password_please_try_again: "Mot de passe invalide, veuillez ré-essayer.",
        the_entered_password_is_not_correct:
            "Le mot de passe que vous avez entré n'est pas correct.",
        too_many_requests_were_recently_made_for_this_account_please_wait_a_bit_before_trying_again:
            "Trop de requêtes ont été effectuées, merci d'attendre un peu avant de ré-essayer.",
        account_disabled: "Le compte a été désactivé",
        please_confirm_your_email_before_logging_in:
            "Veuillez confirmer votre email avant de vous connecter",
        not_connected_to_internet: "Vous n'êtes pas connecté à l'Internet",
        cannot_create_posts_as_you_are_banned:
            "Votre administrateur d'immeuble ne vous autorise pas à poster des annonces. Veuillez le contacter si vous avez des questions.",
        one_of_the_vote_shares_is_negative_which_is_prohibited:
            "Il n'est possible d'avoir un droit de vote négatif.",
        bank_not_created_as_it_already_exists: "La banque n'a pas été créée car elle existe déjà.",
        problem_changing_user_name: "Un problème est survenu lors du changement de nom.",
        not_logged_in: "Vous n'êtes pas connecté.",
        problem_generating_report: "Un problème est survenu lors de la génération du rapport.",
        file_deleted: "Fichier supprimé",
        problem_deleting_file: "Un problème est survenu lors de la suppression du fichier.",
        there_was_a_problem_changing_the_password:
            "Un problème est survenu lors du changement de mot de passe.",
        there_was_a_problem_signing_out: "Un problème est survenu lors de la déconnexion.",
        no_longer_associated_to_building: "Vous n'êtes plus associé à l'immeuble.",
        there_is_already_an_account_associated_with_this_email:
            "Un compte associé à ce courriel existe déjà.",
        please_ensure_you_have_at_least_one_digit_one_letter_and_at_least_12_characters:
            "Veuillez vous assurer que votre mot de passe contient au moins un numéro, une lettre, et 12 caractères.",
        the_password_entered_is_too_weak: "Votre mot de passe n'est pas assez sécuritaire.",
        you_have_not_been_invited_to_join_a_building:
            "Vous n'avez pas été invité à rejoindre un immeuble.",
        invalid_verification_code: "Code de vérification invalide",
        your_verification_code_has_expired_please_request_a_new_one:
            "Votre code de vérification a expiré. Veuillez en demander un nouveau.",
        verification_code_expired: "Code de vérification expiré",
        please_try_again_in_a_few_minutes: "Merci de ré-essayer dans quelques minutes.",
        verification_code_sent: "Code de vérification envoyé.",
        a_verification_code_was_sent_to_your_email:
            "Un code de vérification a été envoyé à votre courriel. Veuillez le saisir afin de continuer.",
        users_were_invited: "Les utilisateurs ont été invités.",
        problem_adding_users: "Une erreur s'est produite lors de l'ajout de l'utilisateur.",
        created_new_fiscal_year: "La nouvelle année fiscale a été créée",
        month_was_reconciled: "Les opérations bancaires ont été conciliées pour ce mois.",
        could_not_find_building: "Impossible de trouver l'immeuble recherché.",
        error_saving_transaction:
            "Une erreur s'est produite lors de la sauvegarde de la transaction",
        click_link_to_reset_password:
            "Veuillez cliquer sur le lien que nous vous avons envoyé à votre adresse courriel afin de réinitialiser votre mot de passe.",
        unable_to_send_email:
            "Nous n'avons pas été en mesure de vous envoyer un courriel à l'adresse que vous nous avez communiquée.",
        appnflat_inc_all_rights_reserved: "© 2022-2025, AppNFlat Inc. Tous droits réservés.",
        a_view_of_appnflat_showing_the_dashboard_with_accounting_graphs:
            "Une capture d'écran de AppNFlat (tableau de bord)",
        a_view_of_appnflat_showing_the_supplier_management_page:
            "Une capture d'écran de AppNFlat (fournisseur)",
        problem_deleting_doc: "Un problème est survenu lors de la suppression du document.",
        closely_monitor_your_revenues_and_expenses: "Suivez de près vos revenues et vos dépenses",
        problem_leaving_building: "Un problème est survenu lors de votre départ de l'immeuble.",
        problem_creating_account: "Une erreur s'est produite lors de la création du compte.",
        problem_updating_user_role:
            "Une erreur s'est produite lors de la mise à jour des permission utilisateur.",
        problem_removing_user: "Une erreur s'est produite lors de la suppression de l'utilisateur.",
        problem_inviting_users: "Une erreur s'est produite lors de l'invitation de l'utilisateur.",
        problem_changing_vote_shares:
            "Suite à un problème, les droits de vote n'ont pas été modifiés.",
        problem_sending_verification_code:
            "Une erreur s'est produite lors de l'envoi du code de vérification.",
        problem_banning_user: "Un problème est survenu lors de l'exclusion de l'utilisateur.",
        error_loading_bank_account_info:
            "Une erreur s'est produite lors du téléchargement des informations bancaires.",
        problem_sending_email_reminder:
            "Une erreur s'est produite lors de l'envoie du courriel de relance.",
        error_changing_owners: "Un problème est survenu lors du changement de propriétaire.",
        error_creating_a_new_fiscal_year: "Erreur lors de la création d'une nouvelle année fiscale",
        problem_sending_unit_statement:
            "Une erreur s'est produite lors de l'envoi de l'état de compte.",
        problem_updating_bank_account_details:
            "Une erreur s'est produite lors de la mise à jour des informations relatives au compte en banque.",
        error_connecting_to_billing_portal:
            "Un problème est survenu en se connectant au portail de payment.",
        error_sending_to_otonom:
            "Une erreur s'est produite lors de l'envoi du fichier à Otonom Solution.",
        problem_generating_otonom_csv:
            "Une erreur s'est produite lors de la génération du des données pour Otonom Solution.",
        problem_close_unit_account: "Impossible de clôturer ce compte.",
        unknown_error_while_setting_approval: "Erreur inconnue lors de l'approbation.",
        problem_create_transactions:
            "Une erreur s'est produite lors de la création de la transaction.",
        error_deleting_bank_account_info:
            "Une erreur est survenue lors de la suppression des coordonnées bancaires.",
        error_updating_reconciled_months:
            "Une erreur est survenue lors de la mise à jour de la conciliation.",
        problem_creating_transaction: "Problème lors de la création de la transaction",
        problem_cancelling_transaction:
            "Un problème est survenu lors de l'annulation de la transaction.",
        problem_create_emailTemplates:
            "Une erreur s'est produite de la création du modèle de courriel.",
        problem_archive_emailTemplates:
            "Une erreur s'est produite lors de l'archivage du modèle de courriel.",
        problem_edit_emailTemplates:
            "Une erreur s'est produite lors de la modification du modèle de courriel.",
        problem_archive_checks: "Une erreur s'est produite lors de l'archivage du chèque.",
        problem_create_checks: "Une erreur s'est produite lors de la création du chèque.",
        problem_create_penalties: "Une erreur s'est produite lors de la création de la pénalité.",
        // problem_archive_posts: "Une erreur s'est produite lors de l'archivage du message.",
        // problem_archive_budgets: "Une erreur s'est produite lors de l'archivage du budget.",
        problem_create_posts: "Une erreur s'est produite lors de la création du message.",
        problem_create_budgets: "Une erreur s'est produite lors de la création du budget.",
        problem_archive_parkings: "Une erreur s'est produite lors de l'archivage du parking.",
        problem_archive_lockers: "Une erreur s'est produite lors de l'archivage du casier.",
        problem_archive_units: "Une erreur s'est produite lors de l'archivage de l'unité.",
        problem_archive_banks: "Une erreur s'est produite lors de l'archivage du compte en banque.",
        problem_archive_categories: "Une erreur s'est produite lors de l'archivage du compte.",
        problem_archive_people: "Une erreur s'est produite lors de l'archivage de la personne.",
        problem_archive_suppliers: "Une erreur s'est produite lors de l'archivage du fournisseur.",
        problem_create_suppliers: "Une erreur s'est produite lors de la création du fournisseur.",
        problem_create_people: "Une erreur s'est produite lors de la création de la personne.",
        problem_create_banks: "Une erreur s'est produite lors de la création du compte en banque.",
        problem_create_units: "Une erreur s'est produite lors de la création de l'unité.",
        problem_create_parkings: "Une erreur s'est produite lors de la création du parking.",
        problem_create_lockers: "Une erreur s'est produite lors de la création du casier.",
        problem_create_categories: "Une erreur s'est produite lors de la création du compte.",
        problem_create_requests: "Une erreur s'est produite lors de la création de la requête.",
        problem_create_requestTags:
            "Une erreur s'est produite lors de la création du tag de la requête.",
        problem_create_requestEmails:
            "Une erreur s'est produite lors de la création de l'email de la requête.",
        problem_edit_checks: "Une erreur s'est produite lors de la modification du chèque.",
        problem_edit_penalties: "Une erreur s'est produite lors de la modification de la pénalité.",
        problem_edit_buildings: "Une erreur s'est produite lors de la modification de l'immeuble.",
        problem_edit_posts: "Une erreur s'est produite lors de la modification du message.",
        problem_edit_budgets: "Une erreur s'est produite lors de la modification du budget.",
        problem_edit_suppliers: "Une erreur s'est produite lors de la modification du fournisseur.",
        problem_edit_people: "Une erreur s'est produite lors de la modification de la personne.",
        problem_edit_banks:
            "Une erreur s'est produite lors de la modification du compte en banque.",
        problem_edit_units: "Une erreur s'est produite lors de la modification de l'unité.",
        problem_edit_parkings: "Une erreur s'est produite lors de la modification du parking.",
        problem_edit_lockers: "Une erreur s'est produite lors de la modification du casier.",
        problem_edit_categories: "Une erreur s'est produite lors de la modification du compte.",
        problem_edit_requests: "Une erreur s'est produite lors de la modification de la requête.",
        problem_edit_requestTags:
            "Une erreur s'est produite lors de la modification du type de la requête.",
        problem_delete_posts: "Une erreur s'est produite lors de la suppression du message.",
        problem_delete_penalties:
            "Une erreur s'est produite lors de la suppression de la pénalité.",
        problem_delete_emailTemplates:
            "Une erreur s'est produite lors de la suppression du modèle d'email.",
        problem_delete_checks: "Une erreur s'est produite lors de la suppression du chèque.",
        problem_delete_requests: "Une erreur s'est produite lors de la suppression de la requête.",
        problem_delete_requestTags:
            "Une erreur s'est produite lors de la suppression du type de la requête.",
        problem_archive_penalties: "Une erreur s'est produite lors de l'archivage des pénalités.",
        problem_deleting_emailTemplates:
            "Une erreur s'est produite lors de la suppression du modèle de courriel.",
        problem_loading_email: "Une erreur s'est produite lors du chargement du courriel.",
        error_reconciling_transaction:
            "Une erreur s'est produite lors de la conciliation de la transaction.",
        unit_NUMBER_sold: "Unité {{number}} (vendue)",
        changed_password: "Mot de passe changé",
        "pre-authorized-debit": "Débit préautorisé",
    },

    en: {
        problem_edit_transaction: "An error occurred while editing the transaction.",
        "pre-authorized-debit": "Pre-authorized Debit",
        unable_to_add_reverse_check: "Unable to return check",
        "unable_to_add_reverse_wire-transfer": "Unable to reverse wire-transfer",
        error_deleting_signature: "An error occurred while deleting the signature",
        error_saving_signature:
            "Une erreur s'est produite lors de la sauvegarde de votre signature.",
        error_archiving_email: "An error occurred while archiving the email.",
        error_assigning_email_to_request:
            "An error occurred while assigning the email to the request.",
        pending_transaction:
            "There are transactions pending before the start of the new fiscal year.",
        new_year_not_after_most_recent_year: "The new fiscal year must be after the most recent.",
        account_created: "Account created",
        your_account_has_been_created_please_sign_in:
            "Your account has been created. Please sign in.",
        request_closed: "The request is already closed",
        error_merging_requests: "Error merging requests.",
        select_bills_to_pay: "Select invoices to be paid",
        problem_printing_check: "An error occurred while printing the check",
        date_and_check_fields_required: "Date and check number are missing",
        now: "Just now",
        yesterday: "Yesterday",
        error: "Error",
        success: "Success",
        unknown_error_occurred: "An unknown error occurred",
        multiple: "Multiple",
        no_transactions_to_send_to_otonom: "No transaction to send to Otonom Solution",
        otonom_paid: "Otonom - Sent",
        otonom_cancelled: "Otonom - Cancelled",
        otonom_readyToSendToOtonom: "Otonom - Ready to Send",
        otonom_waitingApproval: "Otonom - Waiting Approval",
        otonom_error: "Otonom - Error",
        check: "Check",
        "credit-card": "Credit Card",
        "debit-card": "Debit Card",
        otonom: "Otonom Solution",
        "wire-transfer": "Wire Transfer",
        cash: "Cash",
        floor_NUMBER: "Floor {{number}}",
        unit_k: "Unit {{number}}",
        problem_signing_in: "An error occurred while signing in.",
        problem_verifying_email: "An error occurred while verifying email.",
        problem_recovering_email: "An error occurred while recovering email.",
        problem_uploading_file: "A problem occurred while uploading the file.",
        file_uploaded: "File Uploaded",
        invalid_aid_format: "Invalid Account ID",
        aid_already_used_by_another_account:
            "The account number is already used. Please select another one.",
        object_already_exists: "Object you wish to create already exists",
        auth_issue_try_logging_out_and_logging_back_in_or_make_sure_you_have_adequate_permissions:
            "Authorization issue. Please log out and log back in or ensure you have the adequate permissions with your administrator.",
        deleting_post_posted_by_other_user: "Deleting posts posted by other users.",
        bank_not_created_as_it_already_exists: "Bank was not created as it does already exists.",
        default_penalty_cannot_be_created: "Default penalty cannot be created.",
        default_penalty_cannot_be_deleted: "Default penalty cannot be deleted.",
        other_unit_already_has_locker: "Locker is already assigned to another unit.",
        other_unit_already_has_parking: "Parking is already assigned to another unit.",
        transaction_of_wrong_type_cannot_be_approved:
            "Transaction of wrong type cannot be approved.",
        an_invalid_role_was_assigned_to_this_user: "An invalid role was assigned to this user.",
        unit_not_found: "Unit not found.",
        bank_not_found: "Bank was not found in database. Please contact support.",
        unit_not_created_as_it_already_exists: "Unit was not created as it does already exists.",
        bank_not_saved_as_it_is_invalid: "Bank is invalid, please contact support.",
        unit_not_saved_as_it_is_invalid: "Unit is invalid, please contact support.",
        transaction_already_paid_cannot_be_changed: "Transactions already paid cannot be changed.",
        vote_share_total_is_not_100: "The sum of voting rights is not equal to 100%.",
        one_of_the_vote_shares_is_negative_which_is_prohibited:
            "A negative vote share is prohibited.",
        institution_number_should_be_3_digits: "Institution number should be a 3 digit number",
        transit_number_should_be_5_digits: "Transit number should be a 5 digit number",
        account_number_should_be_5_to_20_digits: "Account number should be a 5 to 20 digit number",
        decrypt_error: "Decryption Error",
        encrypt_error: "Encryption Error",
        person_is_still_an_owner_or_resident_of_a_unit:
            "This person is still the owner or tenant of a unit.",
        transaction_had_duplicate_associated_bill:
            "There is a duplicate entry of the Invoice number associated to the transaction.",
        error_connecting_to_otonom_ensure_payee_details_are_saved_with_them:
            "An error occurred while connecting to Otonom Solution. Make sure your payee accounts have the necessary information.",
        month_not_reconciled_as_difference_not_zero:
            "Bank transactions area not reconciled as difference is not null.",
        invalid_otonom_id_for_every_account: "Otonom Solution ID is invalid for all accounts.",
        missing_bank_account_information_for_accounts: "Bank accounts information does not exist.",
        no_account_has_information: "No account has information.",
        submission_to_otonom_failed: "Transfer to Otonom Solution has failed. ",
        otonom_file_not_found: "Otonom Solution file was not found.",
        lock_already_in_place:
            "Someone else is working on this right now, please try again in a few minutes",
        unable_to_place_lock: "Please retry in a few minutes. Contact us if this issue persists",
        invalid_email_please_try_again: "Invalid email, please try again.",
        no_corresponding_user_with_this_email_found: "No corresponding user with this email found.",
        invalid_password_please_try_again: "Invalid password, please try again.",
        the_entered_password_is_not_correct: "The entered password is not correct.",
        too_many_requests_were_recently_made_for_this_account_please_wait_a_bit_before_trying_again:
            "Too many requests were recently made for this account, please wait a bit before trying again.",
        account_disabled: "Account has been disabled",
        please_confirm_your_email_before_logging_in: "Please confirm your email before logging in",
        not_connected_to_internet: "You are not connected to the Internet",
        cannot_create_posts_as_you_are_banned:
            "Your building administrator prevented you from posting. Please contact him for any question.",
        problem_changing_user_name: "There was a problem changing the name.",
        not_logged_in: "You are not logged in.",
        problem_generating_report: "A problem occurred while generating the report.",
        file_deleted: "File Deleted",
        problem_deleting_file: "A problem occurred while deleting the file.",
        there_was_a_problem_changing_the_password: "There was a problem changing the password.",
        there_was_a_problem_signing_out: "There was a problem signing out.",
        no_longer_associated_to_building: "You are no longer associated to the building.",
        there_is_already_an_account_associated_with_this_email:
            "There is already an account associated with this email",
        please_ensure_you_have_at_least_one_digit_one_letter_and_at_least_12_characters:
            "Please ensure you have at least one digit, one letter, and at least 12 characters.",
        the_password_entered_is_too_weak: "The password entered is too weak.",
        you_have_not_been_invited_to_join_a_building:
            "You have not been invited to join a building.",
        invalid_verification_code: "Invalid Verification Code",
        your_verification_code_has_expired_please_request_a_new_one:
            "Your verification code has expired. Please request a new one.",
        verification_code_expired: "Verification code expired",
        please_try_again_in_a_few_minutes: "Please try again in a few minutes.",
        verification_code_sent: "Verification code sent.",
        a_verification_code_was_sent_to_your_email:
            "A verification code was sent to your email. Please enter it to continue.",
        users_were_invited: "Users were invited.",
        problem_adding_users: "An error occurred while adding user.",
        created_new_fiscal_year: "New fiscal year has been created",
        month_was_reconciled: "Bank transactions have been reconciled for this month.",
        could_not_find_building: "Impossible to find the building.",
        error_saving_transaction: "An error occurred while saving transaction",
        click_link_to_reset_password:
            "Please click on the link sent to your email to reset your password.",
        unable_to_send_email: "We were unable to send an email to the email you gave us.",
        a_view_of_appnflat_showing_the_supplier_management_page:
            "A view of AppNFlat showing the supplier management page.",
        a_view_of_appnflat_showing_the_dashboard_with_accounting_graphs:
            "A view of AppNFlat showing the dashboard with accounting graphs.",
        appnflat_inc_all_rights_reserved: "© 2022-2025, AppNFlat Inc. All rights reserved.",
        problem_deleting_doc: "A problem occurred while deleting the document.",
        closely_monitor_your_revenues_and_expenses: "Closely monitor your revenues and expenses.",
        problem_leaving_building: "Problem leaving building",
        problem_creating_account: "An error occurred while creating the account.",
        problem_updating_user_role: "An error occurred while updating user role.",
        problem_removing_user: "An error occurred while removing user.",
        problem_inviting_users: "An error occurred while inviting user.",
        problem_changing_vote_shares: "There was a problem. Vote shares have not been modified.",
        problem_sending_verification_code: "An error occurred while sending the verification code.",
        problem_banning_user: "An error occurred while banning the user.",
        error_loading_bank_account_info: "An error occurred while loading the bank account info.",
        problem_sending_email_reminder: "An error occurred while sending the reminder email.",
        error_creating_a_new_fiscal_year: "Error creating a new fiscal year",
        problem_sending_unit_statement: "An error occurred while sending the unit statement.",
        problem_updating_bank_account_details:
            "A problem occurred while updating bank account details.",
        error_connecting_to_billing_portal:
            "A problem occurred while connecting to the invoice portal.",
        error_sending_to_otonom: "An error occurred while sending the file to Otonom Solution",
        problem_generating_otonom_csv:
            "An error occurred during the generation of the Otonom Solution data.",
        problem_close_unit_account: "Impossible to close this account",
        unknown_error_while_setting_approval: "Unknown error while setting approval",
        problem_create_transactions: "An error occurred while creating the transaction.",
        error_deleting_bank_account_info:
            "An error occurred while deleting the bank account information.",
        error_updating_reconciled_months: "An error occurred during the reconciliation.",
        problem_creating_transaction: "An error occurred while creating the transaction.",
        problem_cancelling_transaction: "A problem occurred while cancelling the transaction.",
        error_changing_owners: "A problem occurred while changing the owner.",
        problem_create_emailTemplates: "An error occurred while creating the email template.",
        problem_archive_emailTemplates: "An error occurred while archiving the email template",
        problem_edit_emailTemplates: "An error occurred while editing the email template",
        problem_edit_buildings: "An error occurred while editing the building.",
        problem_edit_penalties: "An error occurred while editing the penalty.",
        problem_archive_checks: "An error occurred while archiving the check.",
        problem_create_checks: "An error occurred while creating the check.",
        problem_create_penalties: "An error occurred while creating the penalty.",
        problem_edit_posts: "An error occurred while editing the post.",
        problem_edit_budgets: "An error occurred while editing the budget.",
        // problem_archive_posts: "An error occurred while archiving the post.",
        // problem_archive_budgets: "An error occurred while archiving the budget.",
        problem_create_posts: "An error occurred while creating the post.",
        problem_create_budgets: "An error occurred while creating the budget.",
        problem_archive_parkings: "An error occurred while archiving the parking.",
        problem_archive_lockers: "An error occurred while archiving the locker.",
        problem_archive_units: "An error occurred while archiving the unit.",
        problem_archive_banks: "An error occurred while archiving the bank account.",
        problem_archive_categories: "An error occurred while archiving the account.",
        problem_archive_people: "An error occurred while archiving the person.",
        problem_archive_suppliers: "An error occurred while archiving the supplier.",
        problem_create_suppliers: "An error occurred while creating the supplier.",
        problem_create_people: "An error occurred while creating the person.",
        problem_create_banks: "An error occurred while creating the bank account.",
        problem_create_units: "An error occurred while creating the unit.",
        problem_create_parkings: "An error occurred while creating the parking.",
        problem_create_lockers: "An error occurred while creating the locker.",
        problem_create_categories: "An error occurred while creating the account.",
        problem_create_requests: "An error occurred while creating the request.",
        problem_create_requestTags: "An error occurred while creating the request tag.",
        problem_create_requestEmails: "An error occurred while creating the request email.",
        problem_edit_checks: "An error occurred while editing the check.",
        problem_edit_suppliers: "An error occurred while editing the supplier.",
        problem_edit_people: "An error occurred while editing the person.",
        problem_edit_banks: "An error occurred while editing the bank account.",
        problem_edit_units: "An error occurred while editing the unit.",
        problem_edit_parkings: "An error occurred while editing the parking.",
        problem_edit_lockers: "An error occurred while editing the locker.",
        problem_edit_categories: "An error occurred while editing the account.",
        problem_edit_requests: "An error occurred while editing the request.",
        problem_edit_requestTags: "An error occurred while editing the request tag.",
        problem_delete_posts: "An error occurred while deleting the post.",
        problem_delete_penalties: "An error occurred while deleting the penalty.",
        problem_delete_emailTemplates: "An error occurred while deleting the email template.",
        problem_delete_checks: "An error occurred while deleting the check.",
        problem_delete_requests: "An error occurred while deleting the request.",
        problem_delete_requestTags: "An error occurred while deleting the request tag.",
        problem_archive_penalties: "An error occurred while archiving penalties.",
        problem_deleting_emailTemplates: "An error occurred while deleting the email template.",
        problem_loading_email: "An error occurred while loading the email.",
        error_reconciling_transaction: "An error occurred while reconciling the transaction.",
        unit_NUMBER_sold: "Unit {{number}} (sold)",
        changed_password: "Password changed",
    },
}

/** All possible translation keys. We should avoid adding keys to this, prefer TKey whenever possible. */
export type SimpleTKey = keyof (typeof translations)[keyof typeof translations] | "" | undefined

/** Typed version of i18n.t. */
export function translate(tKey: SimpleTKey, opts?: Record<string, string>) {
    if (!tKey) return ""
    const language = navigator.language.startsWith("fr") ? "fr" : "en"
    const _translations = translations[language]
    let translation = tKey in _translations ? (_translations[tKey] ?? tKey) : tKey
    const options = Object.entries(opts ?? {})
    for (let i = 0, n = options.length; i < n; i++) {
        const [option, value] = options[i] ?? []
        if (!option || !value) continue
        translation = translation.replace(new RegExp(`{{${option}}}`, "g"), value)
    }
    return translation
}
