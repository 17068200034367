import { Bank } from "@appnflat-types/Bank"
import { Category } from "@appnflat-types/Category"
import { Supplier } from "@appnflat-types/Supplier"
import { Transaction } from "@appnflat-types/Transaction"
import { Post } from "@appnflat-types/Post"
import { Unit } from "@appnflat-types/Unit"
import { Parking } from "@appnflat-types/Parking"
import { Locker } from "@appnflat-types/Locker"
import { Person } from "@appnflat-types/Person"
import { Check } from "@appnflat-types/Check"
import { InvitedUser } from "@appnflat-types/InvitedUser"
import { EmailTemplate } from "@appnflat-types/EmailTemplate"
import { PreparedOtonomFile } from "@appnflat-types/PreparedOtonomFile"
import { WebCacheCollections } from "store/cacheHelpers"
import { UnitCounts } from "@appnflat-types/Metadata"
import { Penalty } from "@appnflat-types/Penalty"
import { Building } from "@appnflat-types/Building"
import { BuildingUser } from "@appnflat-types/BuildingUser"
import { ArrayOfTypeByCollection, TypeByCollection } from "@appnflat-types/Collection"

/** Helper function for `sortEntries`. For any given entry, it returns a function to apply to an
 * object to get a value to sort it by. */
function entryToSortingFunction(collection: WebCacheCollections) {
    switch (collection) {
        case "banks":
        case "categories":
        case "suppliers":
        case "units":
            return function sortByName(entry: Bank | Category | Supplier | Unit) {
                return entry.aid ?? entry.uuid ?? ""
            }
        case "buildings":
            return function sortByName(entry: Building) {
                return entry.name ?? entry.buildingRef ?? ""
            }
        case "transactions":
        case "unreconciledTransactions":
        case "posts":
            return function sortByDate(entry: Transaction | Post) {
                return String(entry.date ?? entry.uuid ?? "")
            }
        case "parkings":
        case "lockers":
            return function sortByNumber(entry: Parking | Locker) {
                return entry.number ?? entry.uuid ?? ""
            }
        case "users":
            return function sortByUserName(entry: BuildingUser) {
                return (entry.uid ?? "").trim()
            }
        case "people":
            return function sortByPersonName(entry: Person) {
                return `${entry.firstName ?? ""} ${entry.lastName ?? ""}`.trim()
            }
        case "checks":
            return function sortByCheckDate(entry: Check) {
                return `${entry.date ?? ""} ${entry.unitAID ?? ""}`
            }
        case "invitedUsers":
            return function sortByEmail(entry: InvitedUser) {
                return entry.email ?? ""
            }
        case "emailTemplates":
            return function sortByKind(entry: EmailTemplate) {
                return entry.kind ?? ""
            }
        case "preparedOtonomFiles":
            return function sortPreparedOtonomFile(entry: PreparedOtonomFile) {
                return entry.kind === "units" ? "all-units" : `supplier-${entry.supplierUUID}`
            }
        case "metadata":
            return function sortId(entry: { id: string }) {
                return entry.id
            }
        case "penalties":
            return function sortPenalty(entry: Penalty) {
                return ("uuid" in entry ? entry.uuid : entry.kind) ?? ""
            }
        default:
            return function sortByUUID(
                entry: Exclude<
                    TypeByCollection[WebCacheCollections],
                    | Bank
                    | Category
                    | Supplier
                    | Building
                    | Transaction
                    | Post
                    | Unit
                    | Parking
                    | Locker
                    | BuildingUser
                    | Person
                    | Check
                    | InvitedUser
                    | EmailTemplate
                    | PreparedOtonomFile
                    | Penalty
                    | UnitCounts
                >
            ) {
                return String(entry.uuid ?? "")
            }
    }
}

export function sortEntries<C extends WebCacheCollections>(
    collection: C,
    entries: ArrayOfTypeByCollection[C] | undefined
) {
    if (!entries) return undefined
    const sortFunction = entryToSortingFunction(collection)
    return entries.sort((a, b) => {
        // @ts-ignore
        return sortFunction(a).localeCompare(sortFunction(b), undefined, { numeric: true })
    })
}
