import React from "react"
import { Button } from "@mantine/core"
import { IconBrandStripe } from "@tabler/icons-react"
import { useAppParams, useAppTranslation, usePermissions } from "hooks/hooks"
import { setLoadingState } from "logic/notifications"
import { GET, requestHandler } from "server/wrappedCallers"

export function StripePortalButton({ color }: { color?: string }) {
    const t = useAppTranslation()
    const { buildingRef } = useAppParams()
    const { isAdmin } = usePermissions()

    function fetchStripeCustomerPortal() {
        if (!buildingRef) return
        setLoadingState({ tkey: "core:loading", icon: "stripe" })
        requestHandler({
            errorTitle: "error_connecting_to_billing_portal",
            request: GET("/buildings/stripe-portal", {
                params: { query: { buildingRef } },
            }),
            onResult: (result) => {
                window.open(result.url, "_blank")?.focus()
            },
            onFinally: () => setLoadingState(false),
        })
    }

    if (!isAdmin) return undefined

    return (
        <Button color={color} onClick={fetchStripeCustomerPortal}>
            <IconBrandStripe /> {t("core:access_billing")}
        </Button>
    )
}
