import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import type { RootState } from "./store"
import { TKey } from "i18n"
import * as Sentry from "@sentry/react"
import { GlobalUser } from "@appnflat-types/GlobalUser"
import { LoadingIcon } from "components/Other/LoadingIcon"
import { LocalizedString } from "@appnflat-types/types"
import { BuildingUser } from "@appnflat-types/BuildingUser"

export function loadingSelector(state: RootState) {
    return state.app.loading
}

export function userSelector(state: RootState) {
    return state.app.user
}
export function userUIDSelector(state: RootState) {
    return state.app.user?.uid
}
export function userDocsInBuildingsSelector(state: RootState) {
    return state.app.userDocsInBuildings
}
export function dataLoadingStateSelector(state: RootState) {
    return state.app.dataIsLoading.buildings || state.app.dataIsLoading.currentBuilding
}

export type AppState = {
    user?: GlobalUser
    /** A map of buildingRefs to BuidlingUser. */
    userDocsInBuildings: Record<string, BuildingUser>
    /** Whether data is currently being loaded. */
    dataIsLoading: {
        /** Whether the list of buildings the user is in is currently being loaded. */
        buildings: boolean
        /** Whether the current building is currently being loaded. */
        currentBuilding: boolean
    }
    loading:
        | {
              show: false
          }
        | ({
              show: true
              icon?: LoadingIcon
          } & (
              | {
                    tkey: TKey
                }
              | {
                    message: LocalizedString
                }
          ))
}
const initialState: AppState = {
    loading: { show: false },
    userDocsInBuildings: {},
    dataIsLoading: {
        buildings: true,
        currentBuilding: true,
    },
}

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setLoading(
            state,
            action: PayloadAction<
                false | ({ icon?: LoadingIcon } & ({ tkey: TKey } | { message: LocalizedString }))
            >
        ) {
            if (action.payload === false) state.loading = { show: false }
            else state.loading = { show: true, ...action.payload }
        },

        setUser(state, action: PayloadAction<AppState["user"]>) {
            if (action.payload) {
                state.user = {
                    ...(state.user ?? {}),
                    ...action.payload,
                }
                Sentry.setUser({ id: action.payload.uid, email: action.payload.email })
            } else {
                state.user = undefined
                Sentry.setUser(null)
            }
        },

        setUserDocInBuildings(
            state,
            action: PayloadAction<{ buildingRef: string; user: BuildingUser }[]>
        ) {
            state.userDocsInBuildings = Object.fromEntries(
                action.payload.map(({ buildingRef, user }) => [buildingRef, user])
            )
        },

        setDataLoadingState(state, action: PayloadAction<Partial<AppState["dataIsLoading"]>>) {
            state.dataIsLoading = {
                ...state.dataIsLoading,
                ...action.payload,
            }
        },
    },
})

export const {
    /** Sets the current user of the app.
     *
     * If the payload is `undefined`, the user is set to undefined. Otherwise, the user is merged
     * with the current user (i.e., new values take precedence over existing ones).
     */
    setUser,
    setLoading,
    setUserDocInBuildings,
    setDataLoadingState,
} = appSlice.actions
