import { useNavigate } from "react-router"
import { useAppParams } from "./hooks"
import { useCallback } from "react"

/** Whether to include the part of a path.
 * - `always`: always include the part
 * - `whenPresent`: include the part only when it is present
 * - `never`: never include the part
 */
export enum AppURLOptions {
    always,
    whenPresent,
    never,
}

export type AppURL =
    | {
          path: ["dashboard"]
          options: {
              buildingRef: AppURLOptions.never | AppURLOptions.whenPresent
              fiscalYear: AppURLOptions.never | AppURLOptions.whenPresent
          }
      }
    | {
          path: [
              "units" | "banks" | "suppliers" | "categories" | "people",
              "info" | "transactions",
              string,
          ]
          options: { buildingRef: AppURLOptions.always; fiscalYear: AppURLOptions.always }
      }
    | {
          path: ["units", "penalties"]
          options: { buildingRef: AppURLOptions.always; fiscalYear: AppURLOptions.always }
      }
    | {
          path: ["account"]
          options: { buildingRef: AppURLOptions.whenPresent; fiscalYear: AppURLOptions.whenPresent }
      }
    | {
          path: ["signin"] | ["signup"]
          options: { buildingRef: AppURLOptions.never; fiscalYear: AppURLOptions.never }
      }

/** Returns a URL to a valid spot within the app. */
export function useAppURL(params: AppURL): string {
    const { buildingRef, fiscalYear } = useAppParams()
    return appURL({ ...params, buildingRef, fiscalYear })
}

/** Returns a URL to a valid spot within the app. */
export function appURL({
    path,
    options,
    buildingRef,
    fiscalYear,
}: AppURL & { buildingRef: string | undefined; fiscalYear: number | undefined }): string {
    if (
        (options.buildingRef === AppURLOptions.always && !buildingRef) ||
        (options.fiscalYear === AppURLOptions.always && !fiscalYear)
    )
        return "/"

    const buildingPart =
        (
            options.buildingRef === AppURLOptions.always ||
            (options.buildingRef === AppURLOptions.whenPresent && buildingRef)
        ) ?
            `/building/${buildingRef}`
        :   ""
    const fiscalYearPart =
        (
            options.fiscalYear === AppURLOptions.always ||
            (options.fiscalYear === AppURLOptions.whenPresent && fiscalYear)
        ) ?
            `/${fiscalYear}`
        :   ""
    return `${buildingPart}${fiscalYearPart}/${path.join("/")}`
}

/** Returns a function to navigate to a valid spot within the app. */
export function useOnClickNavigate(...params: Parameters<typeof useAppURL>): () => void {
    const url = useAppURL(...params)
    const navigate = useNavigate()
    const onClick = useCallback(() => navigate(url), [navigate, url])
    return onClick
}
