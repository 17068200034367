import { Dinero } from "dinero.js"
import { DineroStorable } from "../@appnflat-types/Common"
import { Currency } from "../@appnflat-types/Currency"
import { DateTime } from "./dates"
import { safeDineroFactory } from "./dineroExtensions"
import { Building } from "../@appnflat-types/Building"
import { Address } from "../@appnflat-types/Address"
import { normalizeCountryName, normalizeStateName } from "./normalizeAddress"
import { Country } from "../@constants/Country"
import { sharedConfig } from "./sharedConfig"
import { Locale } from "../@appnflat-types/Language"

function normalizeLocale(locale: string | undefined): Locale {
    return (locale ?? "").startsWith("fr") ? Locale.French_CA : Locale.English_CA
}

export enum DateFormat {
    /** Print only the date.
     *
     * @example 2024-01-01
     */
    date,
    /** Print the date and time.
     *
     * @example 2024-01-01 14:30
     */
    dateTime,
    /** Print the year and month.
     *
     * @example January 2024
     */
    yearMonth,
}

type CtxState = { locale?: string; buildingDoc?: { currency?: Currency } }

export class Formatter {
    public readonly locale: string
    public readonly currency: Currency
    /** The language of the locale. Only returns supported languages: `"fr" | "en"`. */
    public get language() {
        return this.locale.startsWith("fr") ? "fr" : "en"
    }

    /* Date formatters */
    protected readonly formatterDate: Intl.DateTimeFormat
    protected readonly formatterDateTime: Intl.DateTimeFormat
    protected readonly formatterYearMonth: Intl.DateTimeFormat
    protected readonly formatterRelativeTime: Intl.RelativeTimeFormat
    protected readonly formatterCurrency: Intl.NumberFormat
    protected readonly formatterVoteShare: Intl.NumberFormat

    constructor(building: Building)
    constructor(ctxState: CtxState)
    constructor(locale: string | undefined, currency: Currency | undefined)
    constructor(...args: [Building] | [CtxState] | [string | undefined, Currency | undefined]) {
        let locale: typeof this.locale | undefined
        let currency: typeof this.currency | undefined
        if (typeof args[0] === "object") {
            if ("buildingDoc" in args[0]) {
                const state = args[0] as CtxState
                locale = state.locale
                currency = state.buildingDoc?.currency
            } else {
                const building = args[0] as Building
                locale = building.locale
                currency = building.currency
            }
        } else if (
            (typeof args[0] === "string" || args[0] === undefined) &&
            (typeof args[1] === "string" || args[1] === undefined)
        ) {
            locale = args[0]
            currency = args[1]
        } else {
            throw new Error("Formatter constructor called with invalid arguments.")
        }
        locale = normalizeLocale(locale)
        this.locale = locale ?? "en-CA"
        this.currency = currency ?? "CAD"
        this.formatterDate = new Intl.DateTimeFormat(this.locale, { timeZone: "UTC" })
        this.formatterDateTime = new Intl.DateTimeFormat(this.locale, {
            timeZone: "UTC",
            timeStyle: "short",
        })
        this.formatterYearMonth = new Intl.DateTimeFormat(this.locale, {
            timeZone: "UTC",
            year: "numeric",
            month: "long",
        })
        this.formatterRelativeTime = new Intl.RelativeTimeFormat(this.locale, { numeric: "auto" })
        this.formatterCurrency = new Intl.NumberFormat(this.locale, {
            style: "currency",
            currencyDisplay: "narrowSymbol",
            currency: this.currency,
            maximumFractionDigits: 2,
        })
        this.formatterVoteShare = new Intl.NumberFormat(this.locale, {
            maximumSignificantDigits: sharedConfig.MAX_VOTE_SHARE_PRECISION,
            style: "percent",
        })
    }

    /** Given a UNIX epoch, returns a string in the locale specified in the constructor,
     * while ignoring the time zone.
     *
     * @param date The date to convert.
     * @param format The format to use. Defaults to `DateFormat.date`.
     */
    public dateToLocale(date: DateTime, format = DateFormat.date): string {
        const localDate = date.toDate()
        if (format === DateFormat.date) {
            return this.formatterDate.format(localDate)
        } else if (format === DateFormat.dateTime) {
            return this.formatterDateTime.format(localDate)
        } else {
            return this.formatterYearMonth.format(localDate)
        }
    }

    /** Returns a string representing the relative time between the given date and now. */
    public relativeTime(date: DateTime): string {
        const time = date.toSeconds()
        // Get the amount of seconds between the given date and now
        const deltaSeconds = Math.round(time - new DateTime("now").toSeconds())
        // Array representing one minute, hour, day, week, month, etc in seconds
        const cutoffs = [60, 3600, 86400, 86400 * 7, 86400 * 30, 86400 * 365, Infinity]
        // Array equivalent to the above but in the string representation of the units
        const units: Intl.RelativeTimeFormatUnit[] = [
            "second",
            "minute",
            "hour",
            "day",
            "week",
            "month",
            "year",
        ]
        // Grab the ideal cutoff unit
        const unitIndex = cutoffs.findIndex((cutoff) => cutoff > Math.abs(deltaSeconds))
        // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
        // is one day in seconds, so we can divide our seconds by this to get the # of days
        const divisor = unitIndex ? (cutoffs[unitIndex - 1] ?? 1) : 1
        return this.formatterRelativeTime.format(
            Math.floor(deltaSeconds / divisor),
            units[unitIndex] ?? "second"
        )
    }

    /** Normalizes the formatting of a zip code for different countries.
     *
     * @example
     * ```ts
     * normalizeZipCode("a1b2c3", Country.Canada) // "A1B 2C3"
     * normalizeZipCode("123456789", Country.UnitedStates) // "12345-6789"
     * normalizeZipCode("12 345", Country.UnitedStates) // "12345"
     * normalizeZipCode("1234 5", Country.France) // "12345"
     * normalizeZipCode("12 345", Country.Canada) // "12 345" (unknown format)
     * ```
     */
    private normalizeZipCode(zip: string | undefined, country: Country | null | undefined) {
        if (!zip) return zip
        const nZip = zip.replace(/\s/g, "").toUpperCase()
        switch (country) {
            case Country.Canada:
                return nZip.length === 6 ? nZip.slice(0, 3) + " " + nZip.slice(3) : zip.trim()
            case Country.UnitedStates:
                return /^\d{9}$/.test(nZip) ? `${nZip.slice(0, 5)}-${nZip.slice(5)}` : zip.trim()
            case Country.France:
                return /^\d{5}$/.test(nZip) ? nZip : zip.trim()
            default:
                break
        }
        return zip.trim()
    }

    /** Given an address, returns a properly formatted Address object. */
    public address(address: Address | undefined) {
        if (!address) return undefined
        const country = normalizeCountryName(address.country)
        const zip = this.normalizeZipCode(address.zip, country?.symbol)
        const state = normalizeStateName(address.state)
        return {
            street: address.street,
            city: address.city,
            zip,
            state,
            country,
        }
    }

    /** Given an address, returns a properly formatted string displaying it.
     *
     * @example
     * ```ts
     * address({  })
     * ```
     */
    public addressAsString(address: Address | undefined) {
        const formattedAddress = this.address(address)
        if (!formattedAddress) return ""
        return [
            formattedAddress.street,
            [formattedAddress.city, formattedAddress.zip, formattedAddress.state]
                .filter((v): v is string => !!v)
                .join(", "),
            formattedAddress.country?.name,
        ].join("\n")
    }

    /** Normalizes a phone number. */
    public phoneNumber(phone: string | undefined) {
        if (!phone) return ""
        let normalized = phone.replace(/\s|-|\(|\)/g, "")
        if (normalized.startsWith("+")) normalized = normalized.slice(1)
        if (!/^\d+$/.test(normalized)) return phone
        if (normalized.length === 11) {
            if (normalized[0] !== "1") return phone
            return `+1 ${normalized.slice(1, 4)} ${normalized.slice(4, 7)}-${normalized.slice(7)}`
        } else if (normalized.length === 12) {
            return `+${normalized.slice(0, 2)} ${normalized.slice(2, 5)} ${normalized.slice(5, 8)} ${normalized.slice(8)}`
        } else if (normalized.length === 10) {
            return `(${normalized.slice(0, 3)}) ${normalized.slice(3, 6)}-${normalized.slice(6)}`
        } else if (normalized.length === 7) {
            return `${normalized.slice(0, 3)} ${normalized.slice(3)}`
        }
        return phone
    }

    /** Converts a given amount to a string containing the currency symbol and amount in numbers.
     *
     * @param amount Can be a number, Dinero, DineroStorable.
     * @example
     * ```ts
     * amount(12.36) // "12.36 $"
     * amount(9845.9) // "9,845.90 $"
     * ```
     */
    public amount(amount: number | Dinero | DineroStorable | undefined) {
        const value =
            amount === undefined ? 0
            : typeof amount === "number" ? amount
            : "toUnit" in amount ? amount.toUnit()
            : safeDineroFactory(amount, this.currency).toUnit()
        return this.formatterCurrency.format(value)
    }

    /*
     * This code is modified from the following:
     * https://github.com/marlun78/number-to-words/blob/master/src/toWords.js.
     */

    /** Converts an integer into English or French words.
     *
     * @param amount The number to convert.
     * @returns The number in words.
     * @throws RangeError if the number is too large or too small.
     * @throws TypeError if the number is not an integer.
     *
     * @example
     * ```typescript
     * const formatter = new Formatter("en-CA", "CAD")
     * console.log(formatter.amountToWords(100)) // "one hundred"
     * console.log(formatter.amountToWords(100.5)) // throws TypeError
     * ```
     */
    public integerToWords(amount: number | Dinero | DineroStorable) {
        const value =
            typeof amount === "number" ? amount
            : "toUnit" in amount ? amount.toUnit()
            : safeDineroFactory(amount, this.currency).toUnit()
        const handler = this.language === "fr" ? integerToWordsFR : integerToWordsEN
        return handler(value)
    }

    /** Converts an amount into English or French words.
     *
     * @param amount The amount to convert.
     * @returns The amount in words.
     * @throws RangeError if the number is too large or too small.
     * @throws Error if the currency is not supported.
     * @example
     * ```typescript
     * const formatter = new Formatter("en-CA", "CAD")
     * console.log(formatter.amountToWords(100)) // "one hundred dollars"
     * console.log(formatter.amountToWords(100.5)) // "one hundred dollars and fifty cents"
     * console.log(formatter.amountToWords(100.05)) // "one hundred dollars and five cents"
     * ```
     */
    public amountToWords(amount: number | Dinero | DineroStorable) {
        const value =
            typeof amount === "number" ? amount
            : "toUnit" in amount ? amount.toUnit()
            : safeDineroFactory(amount, this.currency).toUnit()
        if (this.currency === "USD" || this.currency === "CAD") {
            const dollarsNumber = (value >= 0 ? Math.floor : Math.ceil)(value)
            const centsNumber = Math.abs(Math.round((value - dollarsNumber) * 100))
            const dollarsWords = this.integerToWords(dollarsNumber)
            const centsWords = this.integerToWords(centsNumber)
            const french = this.language === "fr"
            const andWord =
                dollarsNumber !== 0 && centsNumber !== 0 ?
                    french ? " et "
                    :   " and "
                :   ""
            return (
                (dollarsNumber || !centsNumber ?
                    `${dollarsWords} dollar${Math.abs(dollarsNumber) > 1 || dollarsNumber === 0 ? "s" : ""}`
                :   "") +
                (centsNumber ?
                    `${andWord}${centsWords} cent${Math.abs(centsNumber) > 1 || centsNumber === 0 ? "s" : ""}`
                :   "")
            )
        } else {
            throw new Error("Currency not supported.")
        }
    }

    /** Converts a share into a percentage.
     * @param share The share to convert. Should be a number between 0 and 1.
     */
    public voteShare(share: number) {
        if (share < 0 || share > 1) throw new RangeError("Vote share must be between 0 and 1.")
        return this.formatterVoteShare.format(share)
    }
}

const TEN = 10
const ONE_HUNDRED = 100
const ONE_THOUSAND = 1_000
const ONE_MILLION = 1_000_000
const ONE_BILLION = 1_000_000_000
const ONE_TRILLION = 1_000_000_000_000
const ONE_QUADRILLION = 1_000_000_000_000_000
const MAX = 9_007_199_254_740_991

const EN_LESS_THAN_TWENTY = [
    "zero",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
]

const EN_TENTHS_LESS_THAN_HUNDRED = [
    "zero",
    "ten",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
]

const FR_LESS_THAN_TWENTY = [
    "zéro",
    "un",
    "deux",
    "trois",
    "quatre",
    "cinq",
    "six",
    "sept",
    "huit",
    "neuf",
    "dix",
    "onze",
    "douze",
    "treize",
    "quartorze",
    "quinze",
    "seize",
    "dix-sept",
    "dix-huit",
    "dix-neuf",
]

const FR_TENTHS_LESS_THAN_HUNDRED = [
    "zéro",
    "dix",
    "vingt",
    "trente",
    "quarante",
    "cinquante",
    "soixante",
    "soixante-dix",
    "quatre-vingt",
    "quatre-vingt-dix",
]

/** Converts an integer into English words. */
function integerToWordsEN(number: number, words: string[] = []): string {
    if (Math.abs(number) > MAX)
        throw new RangeError("Input is not a safe number, it is either too large or too small.")
    let remainder = number
    let word: string | undefined
    // We’re done
    if (number === 0) return !words.length ? "zero" : words.join(" ")
    // If negative, prepend “minus”
    if (number < 0) {
        words.push("minus")
        number = Math.abs(number)
    }
    if (words.length && words[words.length - 1] !== "minus" && number < 100) words.push("and")
    if (number < 20) {
        remainder = 0
        word = EN_LESS_THAN_TWENTY[number]
    } else if (number < ONE_HUNDRED) {
        remainder = number % TEN
        word = EN_TENTHS_LESS_THAN_HUNDRED[Math.floor(number / TEN)]
        // In case of remainder, we need to handle it here to be able to add the “-”
        if (remainder) {
            word += `-${EN_LESS_THAN_TWENTY[remainder]}`
            remainder = 0
        }
    } else if (number < ONE_THOUSAND) {
        remainder = number % ONE_HUNDRED
        word = integerToWordsEN(Math.floor(number / ONE_HUNDRED)) + " hundred"
    } else if (number < ONE_MILLION) {
        remainder = number % ONE_THOUSAND
        word = integerToWordsEN(Math.floor(number / ONE_THOUSAND)) + " thousand"
    } else if (number < ONE_BILLION) {
        remainder = number % ONE_MILLION
        word = integerToWordsEN(Math.floor(number / ONE_MILLION)) + " million"
    } else if (number < ONE_TRILLION) {
        remainder = number % ONE_BILLION
        word = integerToWordsEN(Math.floor(number / ONE_BILLION)) + " billion"
    } else if (number < ONE_QUADRILLION) {
        remainder = number % ONE_TRILLION
        word = integerToWordsEN(Math.floor(number / ONE_TRILLION)) + " trillion"
    } else if (number <= MAX) {
        remainder = number % ONE_QUADRILLION
        word = integerToWordsEN(Math.floor(number / ONE_QUADRILLION)) + " quadrillion"
    }
    if (word) words.push(word)
    return integerToWordsEN(remainder, words)
}

/** Converts an integer into French words. */
function integerToWordsFR(number: number, words: string[] = []): string {
    if (Math.abs(number) > MAX)
        throw new RangeError("Input is not a safe number, it is either too large or too small.")
    let remainder = number
    let word: string | undefined
    // We’re done
    if (number === 0) return !words.length ? "zéro" : words.join(" ").replaceAll(/\s+/g, " ").trim()
    // If negative, prepend “minus”
    if (number < 0) {
        words.push("moins")
        number = Math.abs(number)
    }
    if (number < 20) {
        remainder = 0
        word = FR_LESS_THAN_TWENTY[number]
    } else if (number < ONE_HUNDRED) {
        let orderOfTen = Math.floor(number / TEN)
        if (orderOfTen === 7) orderOfTen = 6
        else if (orderOfTen === 9) orderOfTen = 8
        remainder = Math.round(number - 10 * orderOfTen)
        word = FR_TENTHS_LESS_THAN_HUNDRED[orderOfTen]
        // In case of remainder, we need to handle it here to be able to add the “-”
        if (remainder) {
            word += `-${FR_LESS_THAN_TWENTY[remainder]}`
            remainder = 0
        }
    } else if (number < ONE_THOUSAND) {
        remainder = number % ONE_HUNDRED
        const quotient = Math.floor(number / ONE_HUNDRED)
        if (quotient === 1) word = "cent"
        else if (!remainder && !words.length) word = integerToWordsFR(quotient) + " cents"
        else word = integerToWordsFR(quotient) + " cent"
    } else if (number < ONE_MILLION) {
        remainder = number % ONE_THOUSAND
        const quotient = Math.floor(number / ONE_THOUSAND)
        if (quotient === 1) word = "mille"
        else word = integerToWordsFR(quotient, [""]) + " mille"
    } else if (number < ONE_BILLION) {
        remainder = number % ONE_MILLION
        const quotient = Math.floor(number / ONE_MILLION)
        if (quotient === 1) word = "un million"
        else word = integerToWordsFR(quotient, [""]) + " millions"
    } else if (number < ONE_TRILLION) {
        remainder = number % ONE_BILLION
        const quotient = Math.floor(number / ONE_BILLION)
        if (quotient === 1) word = "un milliard"
        else word = integerToWordsFR(quotient, [""]) + " milliards"
    } else if (number < ONE_QUADRILLION) {
        remainder = number % ONE_TRILLION
        const quotient = Math.floor(number / ONE_TRILLION)
        if (quotient === 1) word = "un billion"
        else word = integerToWordsFR(quotient, [""]) + " billions"
    } else if (number <= MAX) {
        remainder = number % ONE_QUADRILLION
        const quotient = Math.floor(number / ONE_QUADRILLION)
        if (quotient === 1) word = "un billiard"
        else word = integerToWordsFR(quotient, [""]) + " billiards"
    }
    if (word) words.push(word)
    return integerToWordsFR(remainder, words)
}
