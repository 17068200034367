/**
 * The status of a loading operation.
 */
export enum LoadingStatus {
    /** The operation is still loading. */
    loading,
    /** The operation failed. */
    error,
}

export function isLoadingStatus(value: any): value is LoadingStatus {
    return value === LoadingStatus.loading || value === LoadingStatus.error
}
