import { auth } from "firebaseSetup"
import { showErrorNotification } from "logic/notifications"
import createClient, { Middleware } from "openapi-fetch"
import { paths } from "openapi/backend"
import { baseAPIUrl } from "./baseURL"
import { SimpleTKey } from "logic/textDescriptions/translate"
import { LocalizedString } from "@appnflat-types/types"
import { store } from "store/store"
import { PathWithQuery, queryCacheSelector, setQueryCacheEntry } from "store/queryCache"
import { DateTime, httpDateSchema } from "@shared/dates"
// import { v4 } from "uuid"

const client = createClient<paths>({ baseUrl: baseAPIUrl })

// type DeduplicateCacheEntry =
//     | {
//           madeAt: number
//           response: undefined
//           fetching: true
//           onResult: ((data: any) => void)[]
//       }
//     | { madeAt: number; response: any; fetching: false }
// const deduplicateCache: Record<string, DeduplicateCacheEntry> = {}

// function urlToEndpoint(url: string) {
//     return url.match(/https?:\/\/[^/]+(\/[^?]+)/)?.[1]
// }

// const pathsToDeduplicate = ["/text-description/uid" as const, "/text-description/aid" as const]

// function isPathToDeduplicate(
//     path: string | undefined
// ): path is (typeof pathsToDeduplicate)[number] {
//     return !!path && pathsToDeduplicate.includes(path as any)
// }

// function deduplicationKey<P extends (typeof pathsToDeduplicate)[number]>(
//     p:
//         | {
//               path: P
//               query: paths[P]["get"]["parameters"]["query"] | undefined
//           }
//         | string
// ) {
//     if (typeof p === "string") {
//         const endpoint = p.match(/https?:\/\/[^/]+(\/[^?]+)/)?.[1]
//         if (isPathToDeduplicate(endpoint)) {
//             const buildingRef = p.match(/buildingRef=([^&]+)/)?.[1]
//             if (endpoint === "/text-description/uid") {
//                 const uid = p.match(/uid=([^&]+)/)?.[1]
//                 return `/text-description/uid?uid=${uid}&buildingRef=${buildingRef}`
//             } else {
//                 const aid = p.match(/aid=([^&]+)/)?.[1]
//                 return `/text-description/aid?aid=${aid}&buildingRef=${buildingRef}`
//             }
//         } else return p
//     } else {
//         if (!p.query || typeof p.query !== "object") return v4()
//         const buildingRef = p.query && "buildingRef" in p.query ? p.query.buildingRef : ""
//         if (p.path === "/text-description/uid") {
//             const uid = p.query && "uid" in p.query ? p.query.uid : ""
//             return `/text-description/uid?uid=${uid}&buildingRef=${buildingRef}`
//         } else {
//             const aid = p.query && "aid" in p.query ? p.query.aid : ""
//             return `/text-description/aid?aid=${aid}&buildingRef=${buildingRef}`
//         }
//     }
// }

// // Add middleware that for each request to an endpoint in `pathsToDeduplicate`, we will deduplicate
// // the requests if they are made within a short time frame of each other.
// const deduplicateMiddleware: Middleware = {
//     async onRequest({ request }) {
//         if (request.method !== "GET") return
//         const endpoint = urlToEndpoint(request.url)
//         if (isPathToDeduplicate(endpoint)) {
//             const key = deduplicationKey(request.url)
//             const cachedEntry = deduplicateCache[key]
//             if (!cachedEntry) {
//                 console.log(`No identical request found for key ${key}, adding to cache`)
//                 deduplicateCache[key] = {
//                     madeAt: new DateTime("now").toSeconds(),
//                     response: undefined,
//                     fetching: true,
//                     onResult: [],
//                 }
//             }
//         }
//     },
//     async onResponse({ response, request }) {
//         if (request.method !== "GET") return
//         const endpoint = urlToEndpoint(request.url)
//         if (isPathToDeduplicate(endpoint)) {
//             const key = deduplicationKey(request.url)
//             console.log(`Caching response for deduplication (${key})`)
//             const data = await response.clone().json()
//             const deduplicateEntry = deduplicateCache[key]
//             const callbacks =
//                 deduplicateEntry && "onResult" in deduplicateEntry ? deduplicateEntry.onResult : []
//             deduplicateCache[key] = {
//                 madeAt: new DateTime("now").toSeconds(),
//                 response: data,
//                 fetching: false,
//             }
//             console.log("Set value in cache for deduplication to", data, endpoint)
//             callbacks.forEach((cb) => cb(data))
//         }
//         return undefined
//     },
// }
// client.use(deduplicateMiddleware)

function contentType(body: any): "application/json" | "multipart/form-data" | undefined {
    if (!body || typeof body !== "object") return undefined
    try {
        const stringified = JSON.stringify(body)
        if (stringified.startsWith("{") || stringified.startsWith("[")) return "application/json"
        else return undefined
    } catch {
        return undefined
    }
}

function urlToCacheKey(url: string) {
    return url.replace(baseAPIUrl, "") as PathWithQuery
}

const requestMiddleware: Middleware = {
    async onRequest({ request }) {
        // Add the Authorization header to the request
        const token = await auth.currentUser?.getIdToken()
        request.headers.set("Authorization", `Bearer ${token}`)
        // Set the Content-Type header if it is not set
        if (request.method === "POST" || request.method === "PUT") {
            const type = contentType(request.body)
            if (type) request.headers.set("Content-Type", type)
        }
        // If the request has a If-Modified-Since header, we should check if we have the data
        // in the cache and if not, we should remove the header. Otherwise, we might get a 304
        // response when we should get a 200 response.
        if (request.headers.has("If-Modified-Since")) {
            const cachedEntry = queryCacheSelector(urlToCacheKey(request.url))(store.getState())
            if (!cachedEntry) request.headers.delete("If-Modified-Since")
        }
        return request
    },

    async onResponse({ response, request }) {
        const { body, ...resOptions } = response
        if (response.status === 304) {
            const cachedEntry = queryCacheSelector(urlToCacheKey(request.url))(store.getState())
            if (cachedEntry) return new Response(JSON.stringify(cachedEntry), resOptions)
        }
        if (response.headers.has("Last-Modified") && response.status === 200) {
            // If the request has a If-Modified-Since header and the response is 200, we should cache the data
            const lastModifiedHeader = httpDateSchema.safeParse(
                response.headers.get("Last-Modified")
            )
            if (lastModifiedHeader.success) {
                const data = await response.clone().json()
                store.dispatch(
                    setQueryCacheEntry({
                        path: urlToCacheKey(request.url),
                        response: data,
                        lastModified: new DateTime(lastModifiedHeader.data).toSeconds(),
                        lastUsed: new DateTime("now").toSeconds(),
                    })
                )
            }
        }
        return undefined
    },
}

client.use(requestMiddleware)
export const { GET, POST, PUT, DELETE } = client

// // @ts-expect-error
// export const GET: typeof client.GET = (...p) => {
//     if (isPathToDeduplicate(p[0])) {
//         const key = deduplicationKey({ path: p[0], query: p[1]?.params?.query } as any)
//         const cachedEntry = deduplicateCache[key]
//         if (cachedEntry) {
//             const lastUsed = cachedEntry.madeAt
//             const now = new DateTime("now").toSeconds()
//             if (cachedEntry.fetching) {
//                 console.log("Found a request in progress for deduplication")
//                 return new Promise((resolve) => {
//                     cachedEntry.onResult.push((data) => {
//                         console.log("Request in progress resolved")
//                         resolve(data)
//                     })
//                 })
//             } else if (now - lastUsed < 15) {
//                 console.log("Found a cached value for deduplication")
//                 return Promise.resolve(cachedEntry.response)
//             } else {
//                 console.log("Request is too old, deleting from cache")
//                 delete deduplicateCache[key]
//             }
//         } else {
//             console.log("No identical request found for deduplication")
//         }
//     }
//     console.log("Making request to server")
//     return client.GET(...p)
// }

export function requestErrorHandler(
    errorTitle: SimpleTKey | "silentError" | LocalizedString,
    onError?: (error: any) => void
) {
    return function _errorHandler(error: any) {
        console.error(`Caught error in request: `, error)
        if (errorTitle !== "silentError") showErrorNotification({ title: errorTitle }, error)
        if (onError) onError(error)
    }
}

export function requestResultHandler(errorTitle: SimpleTKey | "silentError" | LocalizedString) {
    return function _defaultHandler<T extends object | void>(result: T): T {
        if (result && "error" in result && result.error) {
            console.error(`Caught error in request: `, result.error)
            if (errorTitle !== "silentError")
                showErrorNotification({ title: errorTitle }, result.error)
        }
        return result
    }
}

export async function requestHandler<R extends object | void, E extends object | void>({
    request,
    errorTitle,
    onError,
    onResult,
    onFinally,
}: {
    request: Promise<{ data?: R; error?: E }>
    errorTitle: SimpleTKey | "silentError" | LocalizedString
    onError?: (error: E | undefined | null) => void
    onResult?: (result: R) => void
    onFinally?: () => void
}) {
    const result = await request.catch((error) => {
        onError?.(error)
        requestErrorHandler(errorTitle, onError)(error)
        onFinally?.()
    })
    console.log("Result of request: ", result)
    if (!result) {
        onFinally?.()
        return
    }
    if ("error" in result && result.error) {
        onError?.(result.error)
        requestResultHandler(errorTitle)(result)
    }
    if ("data" in result && result.data) onResult?.(result.data)
    onFinally?.()
    return result
}
