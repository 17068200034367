import React from "react"
import { useRouteError } from "react-router"
import { useAppTranslation } from "../../hooks/hooks"
import { Title, Text, Button, Container, Group } from "@mantine/core"
import * as classes from "./ErrorElement.module.css"

const localTranslations = {
    reload: {
        en: "Reload page",
        fr: "Recharger la page",
    },
    unknownError: {
        en: "An unknown error occurred, please reload the page.",
        fr: "Une erreur inconnue s'est produite, veuillez recharger la page.",
    },
    contactUs: {
        en: "Contact us if this error persists.",
        fr: "Contactez-nous si cette erreur persiste.",
    },
    updateAvailable: {
        en: "An update is available. Please refresh your page.",
        fr: "Une mise à jour est disponible. Veuillez rafraîchir la page.",
    },
}

export default function ErrorElement(p: { error?: unknown }) {
    const routeError = useRouteError()
    const t = useAppTranslation(localTranslations)
    const error = p.error ?? routeError
    const errorName =
        error && typeof error === "object" && "name" in error && typeof error.name === "string" ?
            error.name
        :   undefined
    const errorMessage =
        (
            error &&
            typeof error === "object" &&
            "message" in error &&
            typeof error.message === "string"
        ) ?
            error.message
        :   undefined
    const shouldRefresh = errorName === "ChunkLoadError"

    if (shouldRefresh) {
        window.location.reload()
        return (
            <Container className={classes.root}>
                <Title className={classes.title}>{t("updateAvailable")}</Title>
                <Group justify="center">
                    <Button variant="outline" size="md" onClick={() => window.location.reload()}>
                        {t("reload")}
                    </Button>
                </Group>
            </Container>
        )
    }

    return (
        <Container className={classes.root}>
            <Title className={classes.title}>{t("unknownError")}</Title>
            <Text c="dimmed" size="lg" ta="center" className={classes.description}>
                {t("contactUs")}
            </Text>
            <Text c="dimmed" size="sm" ta="center" className={classes.description}>
                {errorName && <>{errorName}: </>}
                {errorMessage && <>{errorMessage}</>}
            </Text>
            <Group justify="center">
                <Button variant="outline" size="md" onClick={() => window.location.reload()}>
                    {t("reload")}
                </Button>
            </Group>
        </Container>
    )
}
